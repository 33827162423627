/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { Style } from 'ol/style';
import Filter from './filter/Filter';
import LayerConfig from './LayerConfig';
import LayerStyle from './LayerStyle';
import MapUtils from './MapUtils';
import StyleRule from './style/StyleRule';

export default class LayerStyleComplex {
    styleRules: StyleRule[] = [];

    displayInLayerPanel = false;

    constructor(input: any = null, defaultStyle:LayerStyle|null = null) {
      if (input) {
        if (input.displayInLayerPanel !== undefined) {
          this.displayInLayerPanel = input.displayInLayerPanel;
        }

        input.styleRules.forEach((styleRule: any) => {
          this.styleRules.push(new StyleRule(styleRule));
        });
      }
      if (defaultStyle !== null) {
        this.styleRules.forEach((c) => c.style.setBaseStyle(defaultStyle.getMergedBaseStyle()));
      }
    }

    clearCache() {
      this.styleRules.forEach((r) => r.style.clearCache());
    }

    toSld(layer: LayerConfig): string {
      let sld = '';
      this.styleRules.forEach((r) => {
        sld += LayerStyleComplex.toSldRule(r, layer);
      });

      return sld;
    }

    toJson():any {
      return {
        displayInLayerPanel: this.displayInLayerPanel,
        styleRules: this.styleRules.map((x) => x.toJson()),
      };
    }

    isDefined():any {
      return this.styleRules.length > 0;
    }

    static toSldRule(r: StyleRule, layer: LayerConfig): string {
      const label = r.label ? r.label : r.value;
      console.log(r);
      let filterText = '';
      if (!r.filter) {
        const filter = new Filter();
        try {
          filter.fromCQL(r.value);
          r.filter = filter;
        } catch (e) {
          console.log(e);
        }
        filterText = filter.toSld();
      } else {
        filterText = r.filter.toSld();
      }
      let rule = `<Rule>
        <Name>${label}</Name>
        <Title>${label}</Title>
        <Abstract>${label}</Abstract>
        ${filterText}`;
      if (r.minScaleDenom) {
        rule += `<MinScaleDenominator>${r.minScaleDenom}</MinScaleDenominator>`;
      }
      if (r.maxScaleDenom) {
        rule += `<MaxScaleDenominator>${r.maxScaleDenom}</MaxScaleDenominator>`;
      }
      rule += `${r.style.toSld(layer)}
      </Rule>`;
      if (!r.active && layer.complexStyle.displayInLayerPanel) {
        return '';
      }
      return rule;
    }

    clone(): LayerStyleComplex {
      return new LayerStyleComplex(this.toJson());
    }

    getStyleForFeature(feature: any, resolution?: number): LayerStyle|null {
      let scaleDenominator:any;
      if (resolution) {
        scaleDenominator = MapUtils.getScaleFromResolution(resolution, 'm', true);
      }

      const targetCategory = this.styleRules.find((v:any) => {
        if (scaleDenominator && v.minScaleDenom && scaleDenominator < v.minScaleDenom) {
          return false;
        }
        if (scaleDenominator && v.maxScaleDenom && scaleDenominator > v.maxScaleDenom) {
          return false;
        }

        if ((v.filter === undefined || v.filter.evaluate === undefined)
            && v.value && v.value.length > 0) {
          const filter = new Filter();
          try {
            filter.fromCQL(v.value);
            v.filter = filter;
          } catch (e) {
            console.log(e);
          }
        }

        // pas de filtre
        if (v.filter === undefined) {
          return true;
        }
        return v.filter && v.filter.evaluate(feature);
      });

      if (targetCategory && targetCategory.active) {
        return targetCategory.style;
      }

      return null;
    }

    getActiveCQLFilter():string {
      const activeList = this.styleRules.filter((x) => x.active);
      if (activeList && activeList.length === this.styleRules.length) {
        // all active
        return '';
      }
      let retour = '';
      activeList.forEach((rule:StyleRule) => {
        if (retour && retour.length > 0) {
          retour = `(${retour} AND ${rule.value})`;
        } else {
          retour = rule.value;
        }
      });
      return retour;
    }

    getStyle(feature: any, resolution?: number): Style|null {
      const featureStyle = this.getStyleForFeature(feature, resolution);

      if (featureStyle) {
        return featureStyle.getStyle(feature);
      }

      return null;
    }
}
