/* eslint-disable */ 
/* tslint:disable */ 
import WKT from 'ol/format/WKT';
import * as olExtent from 'ol/extent';
import {
  between as betweenFilter,
  or as orFilter,
  equalTo as equalToFilter,
  greaterThan as greaterThanFilter,
  lessThan as lessThanFilter,
  greaterThanOrEqualTo as greaterThanOrEqualToFilter,
  lessThanOrEqualTo as lessThanOrEqualToFilter,
  like as likeFilter,
  and as andFilter,
  not as notFilter,
  isNull as isNullFilter,
  intersects as intersectsFilter,
  within as withinFilter,
  contains as containsFilter,
  during as duringFilter,
  bbox as bboxFilter,
} from 'ol/format/filter';
import LogicalNary from 'ol/format/filter/LogicalNary';
import Not from 'ol/format/filter/Not';
import Comparison from 'ol/format/filter/Comparison';

/**
 * Class: OpenLayers.Format.CQL
 * Read CQL strings to get <OpenLayers.Filter> objects.  Write
 *     <OpenLayers.Filter> objects to get CQL strings. Create a new parser with
 *     the <OpenLayers.Format.CQL> constructor.
 *
 * Inherits from:
 *  - <OpenLayers.Format>
 */
export default class CQL {
    /**
     * APIProperty: data
     * {Object} When <keepData> is true, this is the parsed string sent to
     *     <read>.
     */
    data= null;

    /**
     * APIProperty: keepData
     * {Object} Maintain a reference (<data>) to the most recently read data.
     *     Default is false.
     */
    keepData=false;

    Filter={
      Comparison: {
        EQUAL_TO: 'PropertyIsEqualTo',
        NOT_EQUAL_TO: '<>',
        LESS_THAN: '<',
        LESS_THAN_OR_EQUAL_TO: '<=',
        GREATER_THAN: '>',
        GREATER_THAN_OR_EQUAL_TO: '>=',
        LIKE: 'PropertyIsLike',
        BETWEEN: 'BETWEEN',
        IS_NULL: 'IS NULL',
      },
      Logical: { AND: 'And', OR: 'Or' },
    };

    tokens = [
      'PROPERTY', 'COMPARISON', 'VALUE', 'LOGICAL',
    ];

    patterns:any = {
      EXPRESSION: /\[|\]|\*|\/|\+|\-/,
      PROPERTY: /^[_a-zA-Z\(\)\[\]\@\:\-\.\,0-9]*/,
      COMPARISON: /^(=|<>|<=|<|>=|>|LIKE)/i,
      IS_NULL: /^IS NULL/i,
      COMMA: /^,/,
      ARITHMETIC: /^(\*|\/|\+|\-)/,
      LOGICAL: /^(AND|OR)/i,
      VALUE: /^('([^']|'')*'|\d+(\.\d*)?|\.\d+)/,
      LPAREN: /^\(/,
      RPAREN: /^\)/,
      SPATIAL: /^(BBOX|INTERSECTS|DWITHIN|WITHIN|CONTAINS)/i,
      NOT: /^NOT/i,
      BETWEEN: /^BETWEEN/i,
      GEOMETRY(text:string) {
        const type = /^(POINT|LINESTRING|POLYGON|MULTIPOINT|MULTILINESTRING|MULTIPOLYGON|GEOMETRYCOLLECTION)/.exec(text);
        if (type) {
          const len = text.length;
          let idx = text.indexOf('(', type[0].length);
          if (idx > -1) {
            let depth = 1;
            while (idx < len && depth > 0) {
              idx++;
              switch (text.charAt(idx)) {
                case '(':
                  depth++;
                  break;
                case ')':
                  depth--;
                  break;
                default:
                                // in default case, do nothing
              }
            }
          }
          // console.log([text.substr(0, idx + 1)]);
          return [text.substr(0, idx + 1)];
        }
      },
      END: /^$/,
    };

    follows :any= {
      LPAREN: ['GEOMETRY', 'SPATIAL', 'PROPERTY', 'VALUE', 'LPAREN'],
      RPAREN: ['NOT', 'LOGICAL', 'END', 'RPAREN', 'COMPARISON', 'ARITHMETIC'],
      PROPERTY: ['COMPARISON', 'BETWEEN', 'COMMA', 'IS_NULL', 'ARITHMETIC', 'RPAREN'],
      BETWEEN: ['VALUE'],
      IS_NULL: ['END', 'RPAREN'],
      COMPARISON: ['VALUE'],
      ARITHMETIC: ['VALUE', 'PROPERTY'],
      COMMA: ['GEOMETRY', 'VALUE', 'PROPERTY'],
      VALUE: ['LOGICAL', 'COMMA', 'RPAREN', 'END', 'ARITHMETIC'],
      SPATIAL: ['LPAREN','LOGICAL'],
      LOGICAL: ['NOT', 'VALUE', 'SPATIAL', 'PROPERTY', 'LPAREN'],
      NOT: ['PROPERTY', 'LPAREN'],
      GEOMETRY: ['COMMA', 'RPAREN'],
    };

    operators :any= {
      '=': this.Filter.Comparison.EQUAL_TO,
      '<>': this.Filter.Comparison.NOT_EQUAL_TO,
      '<': this.Filter.Comparison.LESS_THAN,
      '<=': this.Filter.Comparison.LESS_THAN_OR_EQUAL_TO,
      '>': this.Filter.Comparison.GREATER_THAN,
      '>=': this.Filter.Comparison.GREATER_THAN_OR_EQUAL_TO,
      LIKE: this.Filter.Comparison.LIKE,
      BETWEEN: this.Filter.Comparison.BETWEEN,
      'IS NULL': this.Filter.Comparison.IS_NULL,
    };

    operatorReverse:any = {};

    logicals :any= {
      AND: this.Filter.Logical.AND,
      OR: this.Filter.Logical.OR,
    };

    logicalReverse :any= {};

    precedence :any= {
      RPAREN: 3,
      LOGICAL: 2,
      COMPARISON: 1,
    };

    constructor() {
      let i;
      for (i in this.operators) {
        if (this.operators.hasOwnProperty(i)) {
          this.operatorReverse[this.operators[i]] = i;
        }
      }

      for (i in this.logicals) {
        if (this.logicals.hasOwnProperty(i)) {
          this.logicalReverse[this.logicals[i]] = i;
        }
      }
    }

    tryToken(text:string, pattern:any) {
      if (pattern instanceof RegExp) {
        return pattern.exec(text);
      }
      return pattern(text);
    }

    nextToken(text:string, tokens:any) {
      let i; let token; const
        len = tokens.length;
      for (i = 0; i < len; i++) {
        token = tokens[i];
        const pat = this.patterns[token];
        const matches = this.tryToken(text, pat);
        if (matches) {
          const match = matches[0];
          const remainder = text.substr(match.length).replace(/^\s*/, '');
          // console.log("token match:"+ token)
          // console.log("token pattern:"+ pat)
          // console.log("match:"+ match)
          // console.log("remains:"+remainder)
          return {
            type: token,
            text: match,
            remainder,
          };
        }
      }

      let msg = `Erreur dans l'analyse du Filtre au niveau de : [${text}], nous attendions l'une de ces valeurs: `;
      for (i = 0; i < len; i++) {
        token = tokens[i];
        msg += `\n    ${token}: ${this.patterns[token]}`;
      }

      throw new Error(msg);
    }

    tokenize(text:string) {
      const results = [];
      let token; let
        expect = ['NOT', 'GEOMETRY', 'SPATIAL',  'LPAREN','PROPERTY']; // pas évident de gérer l'ordre ( ou property car on peut avoir des ( dans les propriétées

      do {
        token = this.nextToken(text, expect);
        text = token.remainder;
        expect = this.follows[token.type];
        if (token.type != 'END' && !expect) {
          throw new Error(`No follows list for ${token.type}`);
        }
        results.push(token);
      } while (token.type != 'END');

      return results;
    }

    buildAst(tokens:any) {
      const operatorStack = [];
      const postfix: any[] = [];

      while (tokens.length) {
        const tok = tokens.shift();
        switch (tok.type) {
          case 'PROPERTY':
          case 'GEOMETRY':
          case 'VALUE':
            postfix.push(tok);
            break;
          case 'ARITHMETIC':
          case 'COMPARISON':
          case 'BETWEEN':
          case 'IS_NULL':
          case 'LOGICAL':
            var p = this.precedence[tok.type];

            while (operatorStack.length > 0
                        && (this.precedence[operatorStack[operatorStack.length - 1].type] <= p)
            ) {
              postfix.push(operatorStack.pop());
            }

            operatorStack.push(tok);
            break;
          case 'SPATIAL':
          case 'NOT':
          case 'LPAREN':
            operatorStack.push(tok);
            break;
          case 'RPAREN':
            while (operatorStack.length > 0
                        && (operatorStack[operatorStack.length - 1].type != 'LPAREN')
            ) {
              postfix.push(operatorStack.pop());
            }
            operatorStack.pop(); // toss out the LPAREN

            if (operatorStack.length > 0
                        && operatorStack[operatorStack.length - 1].type == 'SPATIAL') {
              postfix.push(operatorStack.pop());
            }
          case 'COMMA':
          case 'END':
            break;
          default:
            throw new Error(`Unknown token type ${tok.type}`);
        }
      }

      while (operatorStack.length > 0) {
        postfix.push(operatorStack.pop());
      }
      const self = this;
      function buildTree():any {
        const tok = postfix.pop();
        switch (tok.type) {
          case 'LOGICAL':
            var rhs:any = buildTree();
            var lhs:any = buildTree();
            if (tok.text.toUpperCase() == 'AND') {
              return andFilter(lhs, rhs);
            }
            return orFilter(lhs, rhs);

          case 'ARITHMETIC':
            var rhs = buildTree();
            var lhs = buildTree();
            console.log('ARITHMETIC');
            console.log(lhs);
            console.log(rhs);
            return `(${lhs}${tok.text}${rhs})`;
          case 'NOT':
            var operand:any = buildTree();
            return notFilter(operand);
          case 'BETWEEN':
            var min; var max; var
              property;
            postfix.pop(); // unneeded AND token here
            max = buildTree();
            min = buildTree();
            property = buildTree();
            return betweenFilter(property, min, max);

          case 'COMPARISON':
            var value:any = buildTree();
            var property = buildTree();
            if (tok.text.toUpperCase() == 'LIKE') {
              return likeFilter(property, value,'%');
            }
            if (tok.text.toUpperCase() == '=') {
              return equalToFilter(property, value);
            }
            if (tok.text.toUpperCase() == '>') {
              return greaterThanFilter(property, value);
            }
            if (tok.text.toUpperCase() == '<') {
              return lessThanFilter(property, value);
            }
            if (tok.text.toUpperCase() == '>=') {
              return greaterThanOrEqualToFilter(property, value);
            }
            if (tok.text.toUpperCase() == '<=') {
              return lessThanOrEqualToFilter(property, value);
            }
          case 'IS_NULL':
            var property = buildTree();
            return isNullFilter(property);

          case 'VALUE':
            var match = tok.text.match(/^'(.*)'$/);
            if (match) {
              return match[1].replace(/''/g, "'");
            }
            return Number(tok.text);

          case 'SPATIAL':
            switch (tok.text.toUpperCase()) {
              case 'BBOX':
                const maxy:any = buildTree();
                var maxx:any = buildTree();
                var miny:any = buildTree();
                var minx:any = buildTree();
                var prop:any = buildTree();
                if(isNaN(minx)){
                  minx=Number(minx.replace('(','').replace(')',''));//hack to get rid off ( and )
                }
                return bboxFilter(prop, olExtent.boundingExtent([[minx, miny], [maxx, maxy]]));

              case 'INTERSECTS':
                var value = buildTree();
                var property = buildTree();
                return intersectsFilter(property, value);
              case 'WITHIN':
                var value = buildTree();
                var property = buildTree();
                return withinFilter(property, value);

              case 'CONTAINS':
                var value = buildTree();
                var property = buildTree();
                return withinFilter(property, value);

              case 'DWITHIN':
                var distance = buildTree();
                var value = buildTree();
                var property = buildTree();
                throw new Error('Method not implemented.');
            }
          case 'GEOMETRY':
            // console.log(tok.text);
            return (new WKT()).readGeometry(tok.text);
          case 'PROPERTY':
            // let retour="{{"+tok.text+"}}";
            let retour = `${tok.text}`;
            // console.log(retour);
            // Dans le cas d'un calcul,on rajoute des ()
            if (self.tryToken(retour, self.patterns.EXPRESSION) && retour.indexOf(' ')>0) retour = `(${retour})`;
            return retour;
          default:
            return tok.text;
        }
      }

      const result = buildTree();
      if (postfix.length > 0) {
        let msg = 'Remaining tokens after building AST: \n';
        for (let i = postfix.length - 1; i >= 0; i--) {
          msg += `${postfix[i].type}: ${postfix[i].text}\n`;
        }
        throw new Error(msg);
      }

      return result;
    }

    read(text: string) {
      const result = this.buildAst(this.tokenize(text));
      if (this.keepData) {
        this.data = result;
      }
      return result;
    }

    write(filter: any) :any{
      console.log(filter instanceof LogicalNary);
      let tagName=filter.tagName_;
      let conditions=filter.conditions;
      let pattern=filter.pattern;
      let value=filter.value;
      let expression=filter.expression;
      let filters=filter.filters;
      let lowerBoundary=filter.lowerBoundary;
      let upperBoundary=filter.upperBoundary;
      /* if (filter instanceof OpenLayers.Geometry) {
                return filter.toString();
            } */
      if (filter instanceof LogicalNary) {
        if (filter instanceof Not) {
          return `NOT (${this.write(filters[0])})`;
        }
        let res = '(';
        let first = true;
        for (let i = 0; i < conditions.length; i++) {
          if (first) {
            first = false;
          } else {
            res += `) ${this.logicalReverse[tagName]} (`;
          }
          res += this.write(conditions[i]);
        }
        return `${res})`;
      }
      if (filter instanceof Comparison) {
        console.log(filter);
        if (tagName == 'PropertyIsBetween') {
          return `${filter.propertyName} BETWEEN ${
            this.write(lowerBoundary)} AND ${
            this.write(upperBoundary)}`;
        } if (tagName == 'PropertyIsLike') {
          return `${filter.propertyName
          } ${this.operatorReverse[tagName]} ${
            this.write(pattern)}`;
        }
        return (value !== null) ? `${filter.propertyName
        } ${this.operatorReverse[tagName]} ${
          this.write(expression)}` : `${filter.propertyName
        } ${this.operatorReverse[tagName]}`;
      }

      if (typeof filter === 'string') {
        return `'${(<string>filter).replace(/'/g, "''")}'`;
      } if (typeof filter === 'number') {
        return String(filter);
      }

      /* switch (filter.CLASS_NAME) {
                case "OpenLayers.Filter.Spatial":
                    switch(filter.type) {
                        case OpenLayers.Filter.Spatial.BBOX:
                            return "BBOX(" +
                                filter.property + "," +
                                filter.value.toBBOX() +
                                ")";
                        case OpenLayers.Filter.Spatial.DWITHIN:
                            return "DWITHIN(" +
                                filter.property + ", " +
                                this.write(filter.value) + ", " +
                                filter.distance + ")";
                        case OpenLayers.Filter.Spatial.WITHIN:
                            return "WITHIN(" +
                                filter.property + ", " +
                                this.write(filter.value) + ")";
                        case OpenLayers.Filter.Spatial.INTERSECTS:
                            return "INTERSECTS(" +
                                filter.property + ", " +
                                this.write(filter.value) + ")";
                        case OpenLayers.Filter.Spatial.CONTAINS:
                            return "CONTAINS(" +
                                filter.property + ", " +
                                this.write(filter.value) + ")";
                        default:
                            throw new Error("Unknown spatial filter type: " + filter.type);
                    }
                case "OpenLayers.Filter.Logical":

                case "OpenLayers.Filter.Comparison":
                    if (filter.type == OpenLayers.Filter.Comparison.BETWEEN) {
                        return filter.property + " BETWEEN " +
                            this.write(filter.lowerBoundary) + " AND " +
                            this.write(filter.upperBoundary);
                    } else {
                        return (filter.value !== null) ? filter.property +
                            " " + operatorReverse[filter.type] + " " +
                            this.write(filter.value) : filter.property +
                            " " + operatorReverse[filter.type];
                    }
                case undefined:
                    if (typeof filter === "string") {
                        return "'" + filter.replace(/'/g, "''") + "'";
                    } else if (typeof filter === "number") {
                        return String(filter);
                    }
                default:
                    throw new Error("Can't encode: " + filter.CLASS_NAME + " " + filter);
            } */
    }
}
