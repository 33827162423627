





































































































/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Filter from '@/models/map-context/filter/Filter';
import LayerAttribute from '@/models/map-context/LayerAttribute';
import LayerConfig from '@/models/map-context/LayerConfig';
import { PluginLocation } from '@/models/plugin/PluginLocation';
import EditionService from '@/services/EditionService';
import { EventBus, EVENTS } from '@/services/EventBus';
import { MapService } from '@/services/MapService';
import sanitizeHtml from 'sanitize-html';
import Component from 'vue-class-component';
import PluginComponent from '../plugins/PluginComponent';
import CesiumFeatureInfosPanel from './CesiumFeatureInfosPanel.vue';
import { transform } from 'ol/proj';

declare let Cesium: any;

@Component({
  props: {
    movablePanel:{ default: false },
  },
  components: {
    CesiumFeatureInfosPanel,
  },
})
export default class FeatureInfosPanel extends PluginComponent {
  static ID='feature-infos';

  static NAME='FeatureInfosPanel';

  static MOVABLE='movable';

  static MOVABLE_SWITCH='movableSwitch';

  // !!les 2 propriétés suivantes peuvent contenir des objects Cesium, il ne faut pas que ces propriétés soient reactives, 
  // cela rentre en conflit avec le fonctionnement de cesium et cause de gros pb de performances
  private currentFeature:any;

  private allFeatures:any;

  public keys = Array<any>();

  public showChilds = true;

  isCesiumFeature = false;

  movablePanel!:Boolean;

  isFeatureSelected = false;

  public keysForEdition = Array<any>();

  public layerOfFeature: LayerConfig|null=null;

  edition = false;

  showList=false;

  editionService!:EditionService;

  featureIndex = 0;

  customTemplate:any='';

  customHtmltemplate = false;

  infosContent = '';

  config:any;

  movable = false;

  movableSwitch = false;

  isDragging = false;

  modalLeft = 0;

  modalTop = 120;

  offsetX = 0;

  offsetY = 0;

  width = 0;

  coordinateX='';
  coordinateY='';
  dataProjection:any=null;

  mapId= 'mapcontainer';

  getCustomStyle(movable:boolean, width: number) {
    let r;
    if (movable) {
      r = `left:${this.modalLeft}px;top:${this.modalTop}px`;
    } else {
      r = 'height:100%';
    }
    if (width > 0) {
      r += `;width:calc(${width}vw - 50px);`
    }
    return r;
  }

  initDrag(): void {
    const mainMap = document.getElementById(this.mapId);
      if (!mainMap) return;
    
    const mainMapRect = mainMap.getBoundingClientRect();
    this.modalLeft = mainMapRect.left + mainMapRect.width - this.$el.clientWidth - 32;
  }

  startDragging(event: MouseEvent) {
    if (this.movable) {
      const rect = this.$el.getBoundingClientRect();
      this.offsetX = event.clientX - rect.left;
      this.offsetY = event.clientY - rect.top;
      this.isDragging = true;
    }
  }

  stopDragging() {
    this.isDragging = false;
  }

  dragModal(event: MouseEvent) {
    if (this.isDragging) {
      const mainMap = document.getElementById(this.mapId);
      if (!mainMap) return;

      const mainMapRect = mainMap.getBoundingClientRect();

      // Calcul de la nouvelle position en fonction de la position initiale du curseur et de l'élément
      const newLeft = Math.min(
        Math.max(mainMapRect.left, event.clientX - this.offsetX),
        mainMapRect.right - this.$el.clientWidth
      );
      const newTop = Math.min(
        Math.max(mainMapRect.top, event.clientY - this.offsetY),
        mainMapRect.bottom - this.$el.clientHeight
      );

      // Ajustement de la position en tenant compte de la différence initiale entre la souris et l'élément
      this.modalLeft = newLeft - mainMapRect.left;
      this.modalTop = newTop - mainMapRect.top;
    }
  }

  beforeDestroy():void {
    EventBus.$off(EVENTS.PLUGIN_ACTIVATION_CHANGE, this.onUpdateActive);
    EventBus.$off(EVENTS.SELECT_FEATURE, this.onFeatureSelected);

    if(this.movablePanel){
      document.removeEventListener('mousemove', this.dragModal);
      document.removeEventListener('mouseup', this.stopDragging);
    }

  }

  created() {
    this.loadPlugins();

    EventBus.$on(EVENTS.PLUGIN_ACTIVATION_CHANGE, this.onUpdateActive);

    this.onUpdateActive();
    const mapService = this.getMapService();
    this.config = mapService.config;
    if (mapService instanceof MapService) {
      this.editionService = mapService.editionService;
    }
  }

  mounted() {
    this.allFeatures=[];
    EventBus.$on(EVENTS.SELECT_FEATURE, this.onFeatureSelected);
    EventBus.$on(EVENTS.SELECT_NO_FEATURE, this.cleanSelection);
    
    if(this.movablePanel){
      document.addEventListener('mousemove', this.dragModal);
      document.addEventListener('mouseup', this.stopDragging);
    }

    const selectedFeatures = this.getMapService().selectionService.selectedFeature;
    if (selectedFeatures !== undefined) {
      this.onFeatureSelected(selectedFeatures);
    }
    this.initDrag();
  }

  cleanSelection(){
    this.allFeatures=[];
    this.currentFeature=null;
    this.isFeatureSelected=false;
    this.closePanel();
  }

  onUpdateActive() {
    this.movable = this.pluginService.getBooleanParameter(FeatureInfosPanel.NAME, FeatureInfosPanel.MOVABLE);
    this.movableSwitch = this.pluginService.getBooleanParameter(FeatureInfosPanel.NAME, FeatureInfosPanel.MOVABLE_SWITCH);
    this.updateIcon();
  }

  updateIcon() {
    const plugin = this.pluginService.getByName(FeatureInfosPanel.NAME);
    // to remove the icon
    if (plugin) {
      if (this.movable) {
        plugin.configuration.position = PluginLocation.Modal;
      } else {
        plugin.configuration.position = PluginLocation.LeftToolBar;
      }
    }
    if (!this.movable) {
      EventBus.$emit(EVENTS.OPEN_LEFT_SIDE_MENU, FeatureInfosPanel.NAME);
    }
  }

  toggleMovable() {
    this.movable = !this.movable;
    this.pluginService.setBooleanParameter(FeatureInfosPanel.NAME, FeatureInfosPanel.MOVABLE, this.movable);
    this.updateIcon();
    if (this.movable) {
      this.closePanel();
    }
  }

  closePanel() {
    this.currentFeature = null;
    this.isFeatureSelected = false;
    EventBus.$emit(EVENTS.OPEN_LEFT_SIDE_MENU, '');
  }

  toggleList() {
    this.showList = !this.showList;
  }
  
  getNbFeatures() {
    return this.allFeatures.length;
  }

  onFeatureSelected(features:any) {
    this.initDrag();
    this.featureIndex = 0;
    console.log('onFeatureSelected');
    this.currentFeature = features[this.featureIndex];
    this.customTemplate='';
    // find plugin for custom featureInfos
    this.pluginComponents.forEach((compo:any) => {
      if(compo.default.extendOptions==undefined){
        console.error(compo)
      }
      if(compo.default.extendOptions.methods && compo.default.extendOptions.methods.openOnSelectedFeature && compo.default.extendOptions.methods.openOnSelectedFeature(features)){
        if(this.pluginService.getByName(compo.default.ID)?.isActive){
          this.customTemplate=compo.default.ID;
        }
      }
    });

    this.isFeatureSelected = true;
    if (this.currentFeature.id && this.currentFeature.id instanceof Cesium.Entity && this.currentFeature.id.layerConfig.is3DModel()) { // Cesium feature ? => show Cesium Panel
      this.isCesiumFeature = true;
      return;
    }
    if (this.currentFeature.id && this.currentFeature.id instanceof Cesium.Entity) { // Cesium feature ? => show Cesium Panel
      this.currentFeature = this.currentFeature.id;
    }
    this.allFeatures = features;

    this.parseFeature();
    this.calculateCoords();
  }

  resetCoords() {
    this.dataProjection = null;
    this.coordinateX = '';
    this.coordinateY = '';
  }

  calculateCoords() {
    try {
      const coordinates =  this.currentFeature?.getGeometry()?.getCoordinates();
      if (coordinates) {
        const layer = this.currentFeature.layerConfig;
        if (layer.dataProjection && layer.dataProjection.length > 0) {
          this.dataProjection = layer.dataProjection;
        } else {
          this.dataProjection = 'EPSG:2154';
        }
        const coords = transform(coordinates, 'EPSG:3857', this.dataProjection);
        this.coordinateX = coords[0].toFixed(2);
        this.coordinateY = coords[1].toFixed(2);;
      } else {
        this.resetCoords();
      }
    } catch(err) {
       // Impossible de récupérer les coordonnées
      this.resetCoords();
    }
  }

  hasDataviz() {
    if (this.currentFeature) {
      return this.currentFeature.layerConfig.dataviz.active;
    }
    return false;
  }

  getDatavizUrl() {
    if (this.currentFeature) {
      return this.currentFeature.layerConfig.dataviz.getUrl(this.currentFeature);
    }
    return false;
  }

  parseFeature() {
    if (this.currentFeature) {
      this.edition = false;
      if (this.currentFeature) {
        this.layerOfFeature = this.currentFeature.layerConfig;
        if (this.layerOfFeature && this.layerOfFeature.editing) {
          this.edition = true;
        }
        if (this.layerOfFeature && this.layerOfFeature.ficheInfos.width && this.layerOfFeature.ficheInfos.width>=20 && this.layerOfFeature.ficheInfos.width<=100) {
          this.width = this.layerOfFeature.ficheInfos.width;
        } else {
          this.width = 0;
        }
        this.customHtmltemplate = false;
        this.infosContent ='';
        if (this.layerOfFeature && this.layerOfFeature.ficheInfos.active) {
          this.customHtmltemplate = true;
          const regEx = new RegExp(/{(.*?)}/, 'ig');
          let feature=this.currentFeature;
          let infosContent =(<string>this.layerOfFeature.ficheInfos.content).replace(regEx, (a:any, b:any) => {
            let value = feature ? Filter.evaluateExpression(b, feature.getProperties()) : null;
            
            //if value is a number with lots of decimals, truncate to 3 redmine #21755
            if(!isNaN(parseFloat(value)) && isFinite(value)){
              value=parseFloat(value);
              if( (value+"").indexOf('.')>0){
                let nbDecimale = (value+"").split(".")[1].length;
                if(nbDecimale>5){
                  value=Math.round(value*1000)/1000;
                }
              }
             
            }

            return value != undefined ? value : '';
          });
          infosContent = sanitizeHtml(infosContent,{
            allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ]),
            allowedAttributes: false,
            allowedSchemesByTag: {
              img: [ 'data','https' ]
            }
          });
          this.infosContent = infosContent;// "<div class='ck-content'>"+popupinfosContent+'</div>';
        }
      }

      let allkeys = [];

      if (this.currentFeature.getKeys) {
        allkeys = this.currentFeature.getKeys();
      } else {
        const props = this.currentFeature.getProperties();
        for (const p in props) {
          allkeys.push(p);
        }
      }

      this.keys = Array<any>();

      const self = this;
      if (this.layerOfFeature && this.layerOfFeature.layerProperties
          && this.layerOfFeature.layerProperties.length > 0) {
        const layerProperties = this.layerOfFeature.layerProperties;
        this.keysForEdition = layerProperties.filter((x:LayerAttribute): any => x.name !== this.currentFeature.geometryName_ && x.editable);
        const cf = this.currentFeature;
        this.keysForEdition.forEach((key:any) => {
          key.value = self.getValue(key.name);
        });
        // On sélectionne les clefs d'attributs qu'on veut afficher
        allkeys.forEach((key:any): void => {
          console.log(self.getValue(key));
          if (self.shallDisplayKey(key, layerProperties) && self.getValue(key) !== undefined) {
            self.keys.push(key);
          }
        });
      } else {
        // On en prend que les key dont la valeur existe réellement.
        allkeys.forEach((key:any) => {
          if (self.getValue(key)) {
            self.keys.push(key);
          }
        });
      }

      // On retire la géométrie des attributs à montrer
      const geomName = this.currentFeature.geometryName_;
      if (this.keys.indexOf(geomName) >= 0) {
        this.keys.splice(this.keys.indexOf(geomName), 1);
      }
      const target:any = this.$refs.customContent;
      if (this.layerOfFeature && (<any>this.layerOfFeature).featureInfoContentRenderer) {
        // eslint-disable-next-line no-unused-expressions
        this.layerOfFeature!.featureInfoContentRenderer(self.currentFeature, target);
      }
    } else {
      // reset le panneau de moficiation
      this.currentFeature = null;
      this.layerOfFeature = null;
      this.isFeatureSelected = false;
    }
  }

  modulo(n:number, m:number):number {
    return ((n % m) + m) % m;
  }

  previousFeature() {
    this.changeFeatureIndex(this.modulo((this.featureIndex - 1), this.allFeatures.length));
  }

  nextFeature() {
    this.changeFeatureIndex(this.modulo((this.featureIndex + 1), this.allFeatures.length));
  }

  changeFeatureIndex(featureIndex:number) {
    console.log('changeFeatureIndex '+featureIndex);
    this.featureIndex = featureIndex;
    this.currentFeature = this.allFeatures[this.featureIndex];
    this.getMapService().selectionService.cleanSelection();
    this.getMapService().selectionService.addToSelection(this.currentFeature);
    this.parseFeature();
  }

  getDisplayLabel(feature:any) : string {
    console.log(feature);
    // Pour afficher les valeurs des 4 premiers attributs
    const featureTxt = feature.getKeys().filter((x:any) => x !== 'geometry').slice(0, 4).map((x:any) => feature.get(x))
      .join();
    // return `${feature.layerConfig.title}`;
    return `${feature.layerConfig.title}-${featureTxt}`;
  }
  getDisplayLabelIndex(index:number) : string {
    const f =  this.allFeatures[index];
    // Pour afficher les valeurs des 4 premiers attributs
    const featureTxt = f.getKeys().filter((x:any) => x !== 'geometry').slice(0, 4).map((x:any) => f.get(x))
      .join();
    // return `${feature.layerConfig.title}`;
    return `${f.layerConfig.title}-${featureTxt}`;
  }

  shallDisplayKey(key:string, properties:any) {
    return properties.filter((el:any) => key === el.name && el.display).length > 0;
  }

  isBooleanProperty(property:any) {
    return property.type === 'xsd:boolean';
  }

  isTextProperty(property:any) {
    return property.type === 'xsd:string';
  }

  isDateProperty(property:any) {
    return property.type === 'xsd:date';
  }

  isDateTimeProperty(property:any) {
    return property.type === 'xsd:date-time';
  }

  isNumberProperty(property:any) {
    return property.type === 'xsd:number' || property.type === 'xsd:double' || property.type === 'xsd:int' || property.type === 'xsd:decimal';
  }

  isLink(key:any):boolean {
    const retour = this.getValue(key);
    if (typeof retour === 'string' && retour.indexOf('http') === 0) {
      return true;
    }
    return false;
  }

  isHref(key:any):boolean {
    const retour = this.getValue(key);
    if (typeof retour === 'string' && retour.indexOf('<a href') === 0) {
      return true;
    }
    return false;
  }

  getValue(key:any) {
    // Cesium Entity
    if (this.currentFeature.properties) {
      return this.currentFeature.properties.getValue()[key];
    }
    if (this.currentFeature.get(key) === undefined) {
      return '';
    }
    return this.currentFeature.get(key);
  }

  getLabel(key:any) {
    if (this.layerOfFeature && this.layerOfFeature.layerProperties) {
      const searchAttribute = this.layerOfFeature.layerProperties.filter((data) => data.name === key)[0];
      if (searchAttribute !== undefined && searchAttribute.label !== undefined && searchAttribute.label !== '') {
        return searchAttribute.label;
      }
    }

    return key;
  }

  save(): void {
    console.log('saving feature');
    const self = this;
    this.keysForEdition.forEach((key) => {
      self.currentFeature.set(key.name, key.value);
    });
    if (this.layerOfFeature!.isWFS()) {
      this.editionService.updateFeature(this.currentFeature, this.layerOfFeature!, 'update');
    }

    this.edition = false;
    this.parseFeature();
  }

  cancel(): void {
    this.edition = false;
  }
}
