/* eslint-disable */
import AbstractLayerTreeElement from '@/models/map-context/AbstractLayerTreeElement';
import LayerConfig from '@/models/map-context/LayerConfig';
import LayerGroupConfig from '@/models/map-context/LayerGroupConfig';
import MapContext from '@/models/map-context/MapContext';
import MapUtils from '@/models/map-context/MapUtils';
import { Map, Overlay, View } from 'ol';
import { MousePosition, defaults, } from 'ol/control';
import { Coordinate } from 'ol/coordinate';
import { boundingExtent } from 'ol/extent';
import { Image as ImageLayer } from 'ol/layer.js';
import BaseLayer from 'ol/layer/Base';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';
import { bbox } from 'ol/loadingstrategy';
import { fromLonLat, transform, transformExtent } from 'ol/proj.js';
import Projection from 'ol/proj/Projection';
import { ImageWMS, OSM, TileWMS, WMTS, } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import VectorTileSource from 'ol/source/VectorTile';
import { optionsFromCapabilities } from 'ol/source/WMTS';
import { TileCoord } from 'ol/tilecoord';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import EditionService from './EditionService';
import EventBus, { EVENTS } from './EventBus';
import SelectionService from './SelectionService';
import { getRenderPixel } from 'ol/render.js';

import {stylefunction} from 'ol-mapbox-style';
import { GPX, GeoJSON, IGC, KML, MVT, TopoJSON, WFS } from 'ol/format';
import {
  DragAndDrop,
} from 'ol/interaction';
import { createXYZ } from 'ol/tilegrid';
import AbstractMapService from './AbstractMapService';

class MapService extends AbstractMapService{
  
  map!: Map;

  view!: View;

  wmtsResult: any

  public idLayers_count: number = 1;

  public currentZoom: number = 0;

  public mapProjectionCode = 'EPSG:3857';

  public initLocation = [5.1, 47.29];

  public initZoom = 9;

  public mousePosition!:MousePosition;

  selectionService!:SelectionService;

  editionService!:EditionService;

  constructor(){
    super();
  }

  initMap(currentAppConfig: MapContext,mapContainer:any,popupContainer:any, isDebug:boolean) {
    this.config = currentAppConfig;
    if (!this.view && !this.map) {
      this.initLocaleConfig();
      this.view = new View({
        projection: this.mapProjectionCode,
        minZoom: 3
      })
      this.map = new Map({
        controls: defaults({
          attributionOptions: ({
            collapsible: false
          }),
          zoom:false
        }),
        layers: [],
        target: mapContainer,
        view: this.view
      });

      this.map.once('rendercomplete', () => {
        this.map.updateSize();
      });
      
      
      this.addDragAndDropInteraction();
      this.postInit();
      this.selectionService.initOverLay(popupContainer);
      this.zoomToInitialExtent();
      EventBus.$emit(EVENTS.MAP_INIT_END);
    }
    else {
      this.clearLayers();
      this.initLayersFromConfig();
    }
  }
  
  addOtherLayerType(layer: LayerConfig){
    console.log(layer.type + 'non géré ')
  }
  
  addDragAndDropInteraction(){
    const dragAndDropInteraction = new DragAndDrop({
      formatConstructors: [ GPX, GeoJSON, IGC, KML, TopoJSON],
    });
    this.map.getInteractions().extend([dragAndDropInteraction]);
    let self=this;
    dragAndDropInteraction.on('addfeatures', function (event) {
      let features:any=event.features;
      console.log(event)
      const vectorSource = new VectorSource({
        features: features,
      });
      let srcExtent = vectorSource.getExtent();
      srcExtent = transformExtent(srcExtent, self.mapProjectionCode, "EPSG:4326");
      const newLayer:LayerConfig = new LayerConfig({
        title: 'Import '+event.file.name,
        type: 'Vector',
        visible: true,
        editable: false,
        layerProperties: [],
        boundingBoxEPSG4326: srcExtent,
      });
  
      console.log(newLayer);
  
      try {
        self.contextService.getCurrentContext().getLayers().unshift(newLayer);
        self.addLayer(newLayer);
      } catch (error:any) {
        newLayer.addError(error);
        console.log(error);
      }
      newLayer.getOlLayer().setSource(vectorSource);
      self.map.getView().fit(vectorSource.getExtent());
    });
  }

  zoomToInitialExtent():void{
    if(this.initLocation && this.initZoom){
      this.view.setCenter(transform(this.initLocation, 'EPSG:4326', this.mapProjectionCode));
      this.view.setZoom(this.initZoom);
    }
    if(this.config.bbox) {
      const bbox=this.config.bbox;
      let ext = boundingExtent([[bbox.minx, bbox.miny], [bbox.maxx, bbox.maxy]]);
      let repojectedExtent = transformExtent(ext, bbox.srs, this.mapProjectionCode);
      console.log(repojectedExtent);
      console.log(transformExtent(ext, bbox.srs, "EPSG:4326"));
      this.view.fit(repojectedExtent);
      this.updateConfigLocationFromMap();
    }
  }

  destroyMap() {
    if (this.map) {
      this.clearLayers();
      this.map.setTarget('') ;
      this.selectionService.destroyService();
       this.editionService.destroyService();
    }
    (<any>this.map) = null;
    (<any>this.view) = null;

  }

  updateConfigLocationFromMap(){
    this.config.zoom = parseFloat((<any>this.map.getView().getZoom()).toFixed(2));
    let newLocation = transform((<any>this.map.getView().getCenter()), this.mapProjectionCode, "EPSG:4326");
    let loca:number[] = [];
    loca.push(parseFloat(newLocation[0].toFixed(5)));
    loca.push(parseFloat(newLocation[1].toFixed(5)));
    this.config.coords=loca;
  }

  clearLayers() {
    let layers = this.map.getLayers().getArray();
    for (var i = 0; i < layers.length; i++) {
      var layer = layers[i];
      if (layer instanceof TileLayer && layer.getSource() instanceof TileWMS && layer.getVisible()) {
        this.map.removeLayer(layer);
      } else if (layer instanceof TileLayer && layer.getSource() instanceof WMTS && layer.getVisible()) {
        this.map.removeLayer(layer);
      }
    }
  }

  postInit() {
    console.log('postInit');
    this.currentScale = MapUtils.getScaleFromResolution(<number>this.view.getResolution(), this.map.getView().getProjection().getUnits(), true);
    this.selectionService=new SelectionService(this);
    this.editionService=new EditionService(this);
    this.map.on('moveend', this.onZoom.bind(this));
    this.initLayersFromConfig();
    console.log('postInit end');
  }
 
  onZoom(event:any) {
    const newScale = MapUtils.getScaleFromResolution(<number>this.view.getResolution(), this.view.getProjection().getUnits(), true);
    if(this.currentScale!=newScale){
      EventBus.$emit(EVENTS.MAP_CURRENT_ZOOM_LEVEL_CHANGE,newScale)  
    }
    this.currentScale = newScale;
    this.currentZoom = <number>this.map.getView().getZoom();
    const mapExtent = this.getMapBoundsWGS84();
    EventBus.$emit(EVENTS.MAP_CURRENT_BBOX_CHANGE,mapExtent)  
  }

  zoomToBboxWGS84(bbox:number[]){
    let ext = boundingExtent([[bbox[0], bbox[1]], [bbox[2], bbox[3]]]);
    ext = transformExtent(ext, "EPSG:4326", this.mapProjectionCode);
    this.map.getView().fit(ext, <any>this.map.getSize());
  }

  zoomToExtent(extent:number[]){
    this.map.getView().fit(extent, <any>this.map.getSize());
  }

  zoomToLayer(layer:LayerConfig) { 
    const [minx, miny, maxx, maxy] = <any>layer.boundingBoxEPSG4326;
    let ext = boundingExtent([[minx, miny], [maxx, maxy]]);
    ext = transformExtent(ext, "EPSG:4326", this.mapProjectionCode);
    this.map.getView().fit(ext, <any>this.map.getSize());
  }

  zoomTo(location:Coordinate, zoomlevel:number, lon?:number, lat?:number) {
    if (lon && lat) {
      location = [+lon, +lat];
    }
    this.map.getView().setCenter(transform(location, 'EPSG:4326', 'EPSG:3857'));
    this.map.getView().setZoom(zoomlevel);
  }

  panTo(location:Coordinate, lon?:number, lat?:number) {
    if (lon && lat) {
      location = [+lon, +lat];
    }
    this.map.getView().setCenter(transform(location, 'EPSG:4326', 'EPSG:3857'));
  }

  addOverlay(loc:Coordinate) {
    var pos = fromLonLat(loc);
    console.log(loc);
    var marker = new Overlay({
      position: pos, 
      positioning: 'center-center',
      element: <any>document.getElementById('marker'),
      stopEvent: false
    });
    this.map.addOverlay(marker);
    return marker;
  }

  initLocaleConfig() {
    this.mapProjectionCode = this.config.projection;
    this.initZoom = this.config.zoom;
    this.initLocation = this.config.coords;
  }

  addOSMLayer(layercfg: LayerConfig){
    let olLayer = new TileLayer({
        source: new OSM(),
        visible: layercfg.visible,
      });
    this.setZlevelOpacityAndResolutions(olLayer, layercfg);
    layercfg.setOL_Layer(olLayer);
    layercfg.baseLayer = true;
    this.map.addLayer(olLayer);
    if(!layercfg.Attribution.Title ){
      layercfg.Attribution.Title = '© OpenStreetMap';
      layercfg.Attribution.OnlineResource = 'https://www.openstreetmap.org/copyright'
    }
  }
  addTMSLayer(layerconfig: LayerConfig) {

    let options:any={
      format: new MVT(),
      url: layerconfig.url
    };
    if(layerconfig.id === 'mapillary'){
      options.tileGrid= createXYZ({maxZoom: 14});
    }
      

    const layerSource = new VectorTileSource(options);
    layerSource.setAttributions(layerconfig.getAttribution());
    var olLayer = new VectorTileLayer({
      visible: true,
      className:'layer-'+layerconfig.uid, 
      source: layerSource,
      style: layerconfig.getStyleFunction(),
      declutter: true,
    });
    
    fetch(layerconfig.mbStyleUrl).then(function(response) {
      response.json().then(function(glStyle) {
        stylefunction(olLayer, glStyle, layerconfig.layername);
      });
    });
    this.setZlevelOpacityAndResolutions(olLayer, layerconfig);
    layerconfig.setOL_Layer(olLayer);
    this.map.addLayer(layerconfig.getOlLayer());

    (<any>this.getLayersById(layerconfig.id)).setVisible(layerconfig.isLayerVisible());
    return layerconfig;
  }
  addVectorLayer(layerconfig: LayerConfig): LayerConfig {
    
    let attributions = layerconfig.getAttribution();
    let drawSource = new VectorSource({
      attributions: attributions
    });
    if (layerconfig.jsonData && layerconfig.jsonData.length > 0) {
      let data_proj='EPSG:3857';
      if(layerconfig.dataProjection){
        data_proj=layerconfig.dataProjection;
      }
      var feats = (new GeoJSON()).readFeatures(layerconfig.jsonData,{
        dataProjection:data_proj,
        featureProjection:'EPSG:3857'
      }
      );
      drawSource.addFeatures(feats);
    } else if (layerconfig.url != undefined) {
      drawSource = new VectorSource({
        url: layerconfig.url,
        attributions: attributions,
        format: new GeoJSON(),
      });
    }

    const olLayer = new VectorLayer({
      source: drawSource,
      style: layerconfig.getStyleFunction()
    });
    this.setZlevelOpacityAndResolutions(olLayer, layerconfig);
    layerconfig.setOL_Layer(olLayer);

    this.map.addLayer(layerconfig.getOlLayer());

    (<any>this.getLayersById(layerconfig.id)).setVisible(layerconfig.isLayerVisible());
    return layerconfig;
  }

  addWFSLayer(layerconfig: LayerConfig) {
    if (this.getLayersById(layerconfig.id) == null) {

      let urlservice = '';// this.configService.getApplicationConfig().getGeoserverUrl() + '/wfs?';
      if (layerconfig.url != undefined && layerconfig.url != "") {
        urlservice = layerconfig.url;
      }

      let projection = this.mapProjectionCode;
      if (layerconfig.projection) {
        projection = layerconfig.projection;
      }
      let attributions = layerconfig.getAttribution();
      let outputformat = "application/json";
      if (layerconfig.outputFormat) {
        outputformat = layerconfig.outputFormat;
      }

      let format:any = new WFS({});
      if (outputformat.toUpperCase().indexOf('JSON') >= 0) {
        format = new GeoJSON()
      }
      var vectorSource = new VectorSource({
        attributions: attributions,
        format: format,
        loader: this.layerManager.getVectorLoaderFunction(layerconfig),
        strategy: bbox
      });

      let olLayer = new VectorLayer({
        source: vectorSource,
        style: layerconfig.getStyleFunction()
      });
      this.layerManager.addLoadingListener(vectorSource, layerconfig);
      this.setZlevelOpacityAndResolutions(olLayer, layerconfig);
      layerconfig.setOL_Layer(olLayer);
      this.setAttributeDefinition(layerconfig);
      this.map.addLayer(layerconfig.getOlLayer());
    }
    (<any>this.getLayersById(layerconfig.id)).setVisible(layerconfig.isLayerVisible());


    return layerconfig;
  }


  addWMTSLayerFromCapabilities(layerconfig: LayerConfig, wmtsCapabilities:any) {
    let self = this;
    let layername = layerconfig.layername;
    let id = layerconfig.id;

    if (this.getLayersById(id) == null) {
      let options:any;
      try {
        if(layerconfig.outputFormat){
          options = optionsFromCapabilities(wmtsCapabilities, {
            layer: layername,
            format : layerconfig.outputFormat,
          });
        }
        else{
          options = optionsFromCapabilities(wmtsCapabilities, {
            layer: layername,
          });
        }
      }
      catch (e:any) {
        console.log(e);
        if (e.message == "projection is null") {
          layerconfig.addError("Projection non reconnue");
        }
        else {
          layerconfig.addError("Problème d'analyse du getCapabilities") ;
        }
        return;
      }

      if (layerconfig.ignoreUrlInCapabiltiesResponse) {
        var searchMask = "request(=|%3D)getCapabilities";
        var regEx = new RegExp(searchMask, "ig");
        var replaceMask = "";
        options.urls[0] = layerconfig.url.replace(regEx, replaceMask);
      }
      options.attributions = layerconfig.getAttribution();
      options.crossOrigin= 'anonymous';
      if(layername=="ORTHOIMAGERY.ORTHOPHOTOS"){
        // un peu bourrin mais il semble y avoir qq chose de spécifique avec cette couche ORTHO
        //https://geoservices.ign.fr/documentation/services/utilisation-web/affichage-wmts/openlayers-et-wmts
        options.tileGrid= new WMTSTileGrid({
          origin: [-20037508,20037508],
          resolutions: [
            156543.03392804103,
            78271.5169640205,
            39135.75848201024,
            19567.879241005125,
            9783.939620502562,
            4891.969810251281,
            2445.9849051256406,
            1222.9924525628203,
            611.4962262814101,
            305.74811314070485,
            152.87405657035254,
            76.43702828517625,
            38.218514142588134,
            19.109257071294063,
            9.554628535647034,
            4.777314267823517,
            2.3886571339117584,
            1.1943285669558792,
            0.5971642834779396,
            0.29858214173896974
            ],
          matrixIds:["0","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19"],
        });
      }

      var layerSource = new WMTS(/** @type {!olx.source.WMTSOptions} */(options));

      this.layerManager.addLoadingFunction(layerSource, layerconfig, this.contextService.getCsrfToken());
      this.layerManager.addLoadingListener(layerSource, layerconfig);
      let layerOl = new TileLayer({
        source: layerSource
      });
      this.setZlevelOpacityAndResolutions(layerOl, layerconfig);
      layerconfig.setOL_Layer(layerOl);
      self.map.addLayer(layerOl);
    }
    else {
      (<any>layerconfig).ol_Layer = this.getLayersById(id);
      this.setZlevelOpacityAndResolutions(layerconfig.getOlLayer(), layerconfig);
    }
    layerconfig.getOlLayer().setVisible(layerconfig.isLayerVisible());
    this.setAttributeDefinition(layerconfig);
    return layerconfig
  }

  getMapBoundsWGS84() {
    const mapExtent = this.map.getView().calculateExtent(this.map.getSize());
    const repojectedExtent = transformExtent(mapExtent,this.mapProjectionCode,"EPSG:4326");
    return repojectedExtent;
  }

  getMapExtent() {
    return this.map.getView().calculateExtent(this.map.getSize());
  }

  addVectorTileLayerFromCapabilities(layerconfig: LayerConfig, wmtsCapabilities:any) {
    let self = this;
    let layername = layerconfig.layername;
    let lconfig = layerconfig;
    let matrixSet = "EPSG:900913";
    console.log(wmtsCapabilities);
    if (this.getLayersById(layerconfig.id) == null) {

      let outPutFormat = 'application/vnd.mapbox-vector-tile';
      if(layerconfig.outputFormat){
        outPutFormat = layerconfig.outputFormat;
      }
      let options:any = optionsFromCapabilities(wmtsCapabilities, {
        layer: layername,
        matrixSet: matrixSet,
        projection: 'EPSG:900913',
        format: outPutFormat
      });
      console.log(options);
      if (layerconfig.ignoreUrlInCapabiltiesResponse) {
        var searchMask = "request(=|%3D)getCapabilities";
        var regEx = new RegExp(searchMask, "ig");
        var replaceMask = "";

        options.urls[0] = layerconfig.url.replace(regEx, replaceMask);
      }
      var wmts = new WMTS(options);

      
      const stylefunction = layerconfig.getStyleFunction();
      
      let format_cfg = {/*featureClass: Feature*/ };
      if (layerconfig.getIdPropery()) {
        (<any>format_cfg)["idProperty"] = layerconfig.getIdPropery();
      }
      let mvt = new MVT(format_cfg);
      /*let layerSource=new VectorTileSource({
        format: mvt,
        attributions:layerconfig.getAttribution(),
        tileUrlFunction: wmts.getTileUrlFunction(),
        tileGrid: wmts.getTileGrid()
      });*/
      //options.tileUrlFunction = wmts.getTileUrlFunction();
      options.format = mvt;
      let layerSource = new VectorTileSource(options);
      matrixSet=options.matrixSet;
      layerSource.setTileUrlFunction((p0: TileCoord, p1: number, p2: Projection)=>{
        //console.log(p0);
        //console.log(p1);
        //console.log(p2);
        return options.urls[0]+'Request=GetTile'+'&layer='+layername
          +'&style=&tilematrixset='+matrixSet+'&Service=WMTS&Version=1.0.0&Format='
          +'application/vnd.mapbox-vector-tile'+'&TileMatrix='+matrixSet+':'+p0[0]+'&TileCol='+p0[1]+'&TileRow='+p0[2]+'';
      });
      layerSource.setAttributions(layerconfig.getAttribution());


      // this.layerManager.addLoadingFunction(layerSource, layerconfig);
      // this.layerManager.addLoadingListener(layerSource, layerconfig);

      let layer = new VectorTileLayer({
        className:'layer-'+layerconfig.uid, 
        style: stylefunction,
        source: layerSource
      });
      this.setZlevelOpacityAndResolutions(layer, layerconfig);
      lconfig.setOL_Layer(layer);
      // this.setAttributeDefinition(layerconfig);
      setTimeout( (x:any)=>{
        this.setAttributeDefinition(layerconfig);
        } , 5000 );
      self.map.addLayer(layer);

    }

    (<any>this.getLayersById(layerconfig.id)).setVisible(layerconfig.isLayerVisible());

    return lconfig;
  }

  sortByZIndexgetLayersAsArray(): LayerConfig[] {
    let clonedList = this.getOrderedLayerList().map((x) => x);
    clonedList.sort(function (a, b) {
      return a.getOlLayer() && b.getOlLayer() && a.getOlLayer().getZIndex() - b.getOlLayer().getZIndex();
    })
    clonedList.reverse();

    return clonedList;
  }



  addWMSLayer(layerconfig: LayerConfig) {

    if (this.getLayersById(layerconfig.id) == null) {

      let urlwms = '';
      if (layerconfig.url != undefined && layerconfig.url != "") {
        urlwms = layerconfig.url;
      }
      var searchMask = "request(=|%3D)getCapabilities";
      var regEx = new RegExp(searchMask, "ig");
      var replaceMask = "";

      urlwms = urlwms.replace(regEx, replaceMask);


      let projection = this.mapProjectionCode;
      if (layerconfig.projection) {
        projection = layerconfig.projection;
      }

      let attributions = layerconfig.getAttribution();

      const optionsWMS={
        attributions: attributions,
        url: urlwms,
        crossOrigin: 'anonymous',
        params: { 'LAYERS': layerconfig.layername, 'EXCEPTIONS': 'XML' },
        projection: projection
      };
      let source:any;
      let olLayer:any;
      if(!layerconfig.tiled){
        source = new ImageWMS(optionsWMS);
        olLayer = new ImageLayer({
          source: source
        });
      }
      else{
        source = new TileWMS(optionsWMS);
        olLayer = new TileLayer({
          source: source
        });
      }
      
      this.layerManager.addLoadingFunction(source, layerconfig, this.contextService.getCsrfToken());
      this.layerManager.addLoadingListener(source, layerconfig);
      this.setZlevelOpacityAndResolutions(olLayer, layerconfig);
      layerconfig.setOL_Layer(olLayer);

      if (layerconfig.selectedStyle && layerconfig.selectedStyle.length>0) {
        source.updateParams({ 'STYLES': layerconfig.selectedStyle });
      }
      else if (layerconfig.styles) {
        source.updateParams({ 'STYLES': layerconfig.styles[0].style });
        layerconfig.selectedStyle = layerconfig.styles[0];
      }
      if (layerconfig.getFilterValue().length>0) {
        source.updateParams({ 'CQL_FILTER': layerconfig.getFilterValue() });
      }
      if (layerconfig.getTimeValue().length>0){
        source.updateParams({ 'TIME': layerconfig.getTimeValue()})
      }
      if (layerconfig.useSldBody) {
        let sld = layerconfig.styletoSld();
        source.updateParams({ 'SLD_BODY': sld });
      }

      this.map.addLayer(layerconfig.getOlLayer());
      this.setAttributeDefinition(layerconfig);
      this.checkWFSCapacity(layerconfig);
      this.checkWMTSCapacity(layerconfig);
    }

    const layer = this.getLayersById(layerconfig.id);
    if (layer != null)
      layer.setVisible(layerconfig.isLayerVisible());

  }

  checkWFSCapacity(layer:LayerConfig){
    layer.isWFsAble = false;
    this.getFeaturesTypes(layer).subscribe(
      (data:any) => {
        if (data.targetNamespace !== undefined && data.targetPrefix !== undefined) {
          layer.isWFsAble = true;
        }
      },
      (error:any) => {
        console.log(error);
      },
    );
  }


  getLayersById(id: string): BaseLayer | null {
    let layerList = this.getOrderedLayerList().filter(function (layer) {
      return layer.id === id;
    });
    if (layerList != undefined && layerList.length > 0) {
      return layerList[0].getOlLayer();
    }
    else {
      return null;
    }

  }

  changeLayerOrder(source: AbstractLayerTreeElement, target: AbstractLayerTreeElement, location: string) {
    if (source instanceof LayerGroupConfig && source.getAllChilds().indexOf(target) > 0) {
      console.error("cannot drop in child");
      return;
    }
    this.config.changeLayerOrder(source, target, location);

    this.computeZIndexAndVisibility();
  }


  computeZIndexAndVisibility() {
    let layers = this.config.getOrderedLayerList().reverse();
    // Sauvegarde du zIndex de la couche.
    for (let i = 0; i < layers.length; i++) {
      layers[i].layerIndex = i + 1;
      if (layers[i].getOlLayer() === undefined) {
        console.error("Ol layer non présent", layers[i])
      }else{
        layers[i].getOlLayer().setZIndex(i + 1);
        if (!layers[i].baseLayer) {
          layers[i].getOlLayer().setVisible(layers[i].isLayerVisible());
        }
      }
      
      
    }
    this.map.render();
  }

  setZlevelOpacityAndResolutions(layer: BaseLayer, layerconfig: LayerConfig) {
    layer.setZIndex(layerconfig.layerIndex);

    const maxResolution = this.getMinMaxResolutionInfos(layerconfig).maxResolution;
    if (maxResolution) {
      layer.setMaxResolution(maxResolution);
    }

    const minResolution = this.getMinMaxResolutionInfos(layerconfig).minResolution;
    if (minResolution) {
      layer.setMinResolution(minResolution);
    }

    if (layerconfig.opacity!=undefined) {
      layer.setOpacity(layerconfig.opacity);
    }

  }

  removeLayerFromMap(layer: LayerConfig) {
    if (layer.getOlLayer()) {
      this.map.removeLayer(layer.getOlLayer());
      delete layer.ol_Layer;
    }
  }

  getMinMaxResolutionInfos(layerconfig: LayerConfig) {

    var maxResolution = undefined;
    if (layerconfig.maxScaleDenominator) {
      maxResolution = MapUtils.getResolutionFromScale(layerconfig.maxScaleDenominator, this.map.getView().getProjection().getUnits());
    }
    var minResolution = undefined;
    if (layerconfig.minScaleDenominator) {
      minResolution = MapUtils.getResolutionFromScale(layerconfig.minScaleDenominator, this.map.getView().getProjection().getUnits());
    }
    return {
      "maxResolution": maxResolution,
      "minResolution": minResolution
    };
  }
  
  updateLayer(layer: LayerConfig) {
    layer.updateStyle();

    if (layer.authenticationInfos) {
      this.layerManager.addLoadingFunction(layer.getLayerSource(), layer, this.contextService.getCsrfToken());
      let key = layer.url;
      if (layer.authenticationInfos.store) {
        this.contextService.addAuthInfo(key, layer.authenticationInfos);
      }
      else {
        this.contextService.removeAuthInfo(key);
      }
    }

    if (layer.Attribution && layer.getOlLayer()) {
      layer.getLayerSource().setAttributions(layer.getAttribution())
    }
    if (layer.getOlLayer()) {
      this.setZlevelOpacityAndResolutions(layer.getOlLayer(), layer);
    }
    let bbox = layer.boundingBoxEPSG4326;
    if (layer.maxScaleDenominator && layer.minScaleDenominator && layer.maxScaleDenominator < layer.minScaleDenominator) {
      return {
        "success": false,
        "message": "L'échelle maximum de rendu doit être supérieure à l'échelle minimum."
      }

    }
    if (this.checkBboxValidity(bbox)) {

      //Pour WMS il faut mettre à jour la source, pour WFS pas besoin
      if (layer.type == "WMS" && layer.getLayerSource().updateParams) {
        layer.getLayerSource().updateParams({ 'LAYERS': layer.layername });
        if (layer.getTimeValue().length>0){
          layer.getLayerSource().updateParams({ 'TIME': layer.getTimeValue()})
        }

        if (layer.getFilterValue().length>0)
          layer.getLayerSource().updateParams({ 'CQL_FILTER': layer.getFilterValue() });
        else
          layer.getLayerSource().updateParams({ 'CQL_FILTER': undefined });
      }
      else {
        console.log("refresh WFS or VectorTile layer");
        if (layer.getOlLayer()!== undefined && (layer.type == "VectorTile" || layer.isWFS())) {
          layer.getLayerSource().clear();
          layer.getLayerSource().refresh();
        }

      }
      return { "success": true };

    } else {
      return {
        "success": false,
        "message": "Les coordonnées doivent respecter les intervalles [-90; 90] pour minX et maxX, [-180; 180] pour minY et maxY."
      }
    }
  }

  getVectorFeatureAtPixel(event:any,layer:LayerConfig){
    const pixel = event.pixel;
    const retour: any[]=[];
    this.map.forEachFeatureAtPixel(pixel,
      (feature:any, layer:any) => {
        if (layer) {
          feature.layerConfig = layer.customConfig;
          retour.push(feature)
        }
      },{
        layerFilter(l:any) {
          return l === layer.getOlLayer();
        },
      });
      

    return retour;
  }

  setOffsetZ(layer:LayerConfig): void {
    return;
  }
  setOffsetZMin(layer:LayerConfig): void {
    return;
  }
  setOffsetZMax(layer:LayerConfig): void {
    return;
  }


  setScaleZ(layer:LayerConfig): void {
    return;
  }
  setScaleZMin(layer:LayerConfig): void {
    return;
  }
  setScaleZMax(layer:LayerConfig): void {
    return;
  }

  setNord() :void {
    
  }

  preRenderSwipe(event:any, size:any, cutLeft:boolean) {
    const ctx = event.context;
    const mapSize = this.map.getSize();
    if(mapSize) {
      if (!cutLeft) {
        size = 100 - size;
      }
      const width = mapSize[0] * (size / 100);
      let tl, tr, bl, br;
      if (cutLeft) { // Couper à gauche
        tl = getRenderPixel(event, [width, 0]);
        tr = getRenderPixel(event, [mapSize[0], 0]);
        bl = getRenderPixel(event, [width, mapSize[1]]);
        br = getRenderPixel(event, mapSize);
      } else { // Couper à droite
        tl = getRenderPixel(event, [0, 0]);
        tr = getRenderPixel(event, [mapSize[0] - width, 0]);
        bl = getRenderPixel(event, [0, mapSize[1]]);
        br = getRenderPixel(event, [mapSize[0] - width, mapSize[1]]);
      }
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(tl[0], tl[1]);
      ctx.lineTo(bl[0], bl[1]);
      ctx.lineTo(br[0], br[1]);
      ctx.lineTo(tr[0], tr[1]);
      ctx.closePath();
      ctx.clip();
    }
  }

  forceRender() {
    this.map.render();
  }

}
const mapServiceInstance = new MapService();
export default MapService;
export { MapService, mapServiceInstance };

