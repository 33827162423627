/* eslint-disable */ 
import axios from 'axios';
import Feature from 'ol/Feature';
import { Draw, Translate, Snap } from 'ol/interaction';
import Modify from 'ol/interaction/Modify';
import Select from 'ol/interaction/Select';
import Vector from 'ol/source/Vector';
import { WFS,GeoJSON } from 'ol/format';
import { unByKey } from 'ol/Observable';
import { Circle, Fill, Stroke } from 'ol/style';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { fromCircle } from 'ol/geom/Polygon';
import LayerConfig from '@/models/map-context/LayerConfig';
import EditionToolType from '@/models/map-context/EditionToolType';
import Geometry, { Type } from 'ol/geom/Geometry';
import MapService from './MapService';
//import GeometryType from 'ol/geom/GeometryType';
import EventBus, { EVENTS } from './EventBus';

export default class EditionService {
 
  public vectorSource!: Vector<any>;
  //public currentStyle: FeatureStyle;

  public draw!: Draw;

  public selectForDeletion!: Select|undefined;

  public selectForUpdate!: Select|undefined;

  public modify?: Modify;

  public readonly fontPostFix: string = "px Calibri,sans-serif";

  public currentText!: string;

  public currentDescription!: string;

  public isDescriptionEditionActive: boolean = false;

  public activeTool = '';

  private drawStyle: Style;

  translate?: Translate;

  selectForTranslate!: Select|undefined;

  listenerKey: any;

  formatWFS = new WFS();

  mapService:MapService;

  constructor(mapService:MapService) { 
    this.mapService=mapService

    this.drawStyle = new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)'
      }),
      stroke: new Stroke({
        color: 'rgba(0, 0, 255, 0.5)',
        lineDash: [],
        width: 2
      }),
      image: new Circle({
        radius: 7,
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, 0.5)',
          lineDash: [],
          width: 2
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.5)'
        })
      }),
      text: new Text({
        font: '12px Calibri,sans-serif',
        fill: new Fill({ color: '#000' }),
        stroke: new Stroke({
          color: '#fff',
          width: 1
        }),
        // get the text from the feature - `this` is ol.Feature
        text: ''
      }),
      zIndex: 50
    });
  }

  destroyService() {

  }

  activeEdition(type: string, layer: LayerConfig) {
    this.vectorSource = layer.getLayerSource();
    this.activeTool = type;
    EventBus.$emit(EVENTS.DISABLE_SELECTION);
    console.log(type)
    if (type == EditionToolType.ADD_POLYGON) {
      this.addEdition(this.getFeatureType('Polygon'), layer);
    }
    else if (type == EditionToolType.ADD_LINE) {
      this.addEdition(this.getFeatureType('Line'), layer);
    }
    else if (type == EditionToolType.ADD_POINT) {
      this.addEdition(this.getFeatureType('Point'), layer);
    }
    else if (type == EditionToolType.ADD_CIRCLE) {
      this.addEdition('Circle', layer);
    }

  }

  getFeatureType(_default: string): Type {

      switch (_default) {
        case 'Point':
          return  _default;
        case 'Line':
          return  'LineString';
        case 'Polygon':
          return  _default;
        case 'Circle':
          return  _default;
        default:
          return  'Point';
      }
    
  }
  guessFeatureType(_default: string): String {
    let firstFeature = this.vectorSource.getFeatures()[0];
    if (firstFeature && firstFeature.getGeometry()) {
      return (<Geometry>firstFeature.getGeometry()).getType();
    }
    else {
      return this.getFeatureType(_default);
    }
  }


  moveShape(layer: LayerConfig) {
    this.vectorSource = layer.getLayerSource();

    this.activeTool = EditionToolType.MOVE_SHAPE;
    if (!this.translate) {
      this.selectForTranslate = new Select({
        style: this.drawStyle
      });

      this.translate = new Translate({
        features: this.selectForTranslate.getFeatures()
      });


      this.mapService.map.addInteraction(this.selectForTranslate);
      this.mapService.map.addInteraction(this.translate);
      let self = this;
      this.listenerKey = this.translate.on('translateend', (evt: any) => {
        console.log("translateend")
        if (layer.type == 'WFS' && self.vectorSource) {
          self.updateFeature(evt.features.getArray()[0], layer, "update");
        }
        else {
          self.save(layer);
        }
      });
    } else {
      this.translate.setActive(true);
      this.selectForTranslate && this.selectForTranslate.setActive(true);
    }
  }


  getWriteOption(layer: LayerConfig): any {
    return {
      srsName: 'EPSG:3857',
      featureNS: layer.targetNamespace,
      featurePrefix: layer.targetPrefix,
      featureType: layer.layername.split(':')[1]
    };

  }
  /**
   * Active l'outil de modification des formes des editions
   */
  public updateShape(layer: LayerConfig): void {
    this.vectorSource = layer.getLayerSource();

    this.activeTool = EditionToolType.UPDATE_SHAPE;
    if (!this.modify) {
      this.selectForUpdate = new Select({
        style: this.drawStyle
      });
      this.modify = new Modify({
        style: this.drawStyle,
        features: this.selectForUpdate.getFeatures()
      });


      //snapping
      var snap = new Snap({
        source: this.vectorSource
      });

      // This workaround allows to avoid the ol freeze
      // referenced bug : https://github.com/openlayers/openlayers/issues/6310
      // May be corrected in a future version
      (<any>this.modify).handleUpEvent_old = (<any>this.modify).handleUpEvent;
      (<any>this.modify).handleUpEvent = function (evt:any) { try { this.handleUpEvent_old(evt); } catch (ex) { } }

      this.mapService.map.addInteraction(this.selectForUpdate);
      this.mapService.map.addInteraction(this.modify);
      // this.mapService.map.addInteraction(snap);

      let self = this;
      this.listenerKey = this.modify.on('modifyend', (evt: any) => {
        console.log("modif")
        let feature = evt.features.getArray()[0];
        if (layer.type == 'WFS' && self.vectorSource) {
          self.updateFeature(feature, layer, "update");
        }
        else {
          self.save(layer);
        }

      });

    } else {
      this.modify.setActive(true);
      this.selectForUpdate!.setActive(true);
    }
  }



  updateFeature(feature: Feature<any>, layer: LayerConfig, action: string) {

    let featureToBeSaved: any = feature.clone();
    featureToBeSaved.setId(feature.getId());
    // Avant d'envoyer la requête, change le nom de l'attribut geometry
    delete featureToBeSaved.values_.bbox;
    // TODO get real geometry name
    featureToBeSaved.setGeometryName(layer.getGeomAttributeName());

    let toAdd = [];
    let toUpdate = [];
    let toDelete = [];
    if (action == "update") {
      toUpdate.push(featureToBeSaved);
    }
    if (action == "insert") {
      toAdd.push(featureToBeSaved);
    }
    if (action == "delete") {
      toDelete.push(featureToBeSaved);
    }
    // Construit la requête post
    var featureRequest = new WFS().writeTransaction(toAdd, toUpdate, toDelete, this.getWriteOption(layer));

    // serialisation du corps de la requête
    let bodyString: string = new XMLSerializer().serializeToString(featureRequest);

    console.log(bodyString)
    const httpOptions:any = {
      responseType: 'text',
      headers: {
        'Content-Type': 'application/xml',
        'Accept': 'application/xml',
        'Response-Type': 'text', 
      }
    };

    if (layer.hasAuthenticationInfos()) {
      httpOptions.headers['Authorization'] = layer.authenticationInfos.getBasicAutorisationValue();
    }
    axios.post(this.getWFSTransactionUrl(layer), bodyString, httpOptions).then((x) => {
      if (x.data.indexOf("ExceptionText") > 0) {
        console.error(x);
        EventBus.$emit(EVENTS.ERROR, x.data);
      }
      else {
        let reponse:any = new WFS().readTransactionResponse(x.data);
        console.log(reponse)
        //Mise à jour de l'ID si l'insertion est correcte
        if (action == "insert" && (reponse.totalInserted == 1 || reponse.transactionSummary!.totalInserted == 1)) {
          feature.setId(reponse.insertIds[0]);
        }
        EventBus.$emit(EVENTS.NOTIFICATION, 'Enregistrement effectué');
      }

    },(errors)=>{
      console.error(errors);
      EventBus.$emit(EVENTS.ERROR, 'Erreur de la requête de mise à jour');
    })


  }

  getWFSTransactionUrl(layer: LayerConfig): string {
    let url = layer.url;
    const requestRegex = new RegExp('request(=|%3D)getcapabilities', 'ig');
    return url;// .replace(requestRegex,'');
  }
  /**
   * Active l'outil de suppression des editions
   */
  public delete(layer: LayerConfig): void {
    this.vectorSource = layer.getLayerSource();
    this.activeTool = EditionToolType.DELETE;

    if (!this.selectForDeletion) {
      var style = new Style({
        fill: new Fill({
          color: 'rgba(255, 0, 0, 0.2)'
        }),
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, 0.5)',
          width: 2
        }),
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: 'rgba(255, 0, 0, 0.5)'
          })
        }),
        text: new Text({
          font: '12px Calibri,sans-serif',
          fill: new Fill({ color: 'rgba(255, 0, 0, 0.5)' }),
        }),
        zIndex: 50
      });
      this.selectForDeletion = new Select({ style: style });
      this.mapService.map.addInteraction(this.selectForDeletion);
      // Lorsque de nouvelles features sont sélectionnées
      let selected_features = this.selectForDeletion.getFeatures();
      let self = this;
      this.listenerKey = selected_features.on('add', (evt) => {
        var feature = evt.element;
        if (feature) {
          setTimeout(() => {
            if (confirm("Etes-vous sur de vouloir supprimer cet objet ?")) {
              // supprimer l'edition de la sélection
              console.log("suppression")
              this.selectForDeletion!.getFeatures().clear();
              // supprimer l'edition de la carte
              this.vectorSource.removeFeature(feature);

              if (layer.type == 'WFS' && self.vectorSource) {
                self.updateFeature(feature, layer, "delete");
              }
              else {
                self.save(layer);
              }

            }
          }, 300);
        }
      });
    } else {
      this.selectForDeletion.setActive(true);
    }
  }

  /**
 * Supprime le calque des interactions
 */
  public removeInteractions(): void {
    if (this.draw) {
      this.draw.setActive(false);
    }

    //unbind listeners
    if (this.listenerKey) {
      unByKey(this.listenerKey);
    }
    if (this.selectForDeletion) {
      this.removeSelectInteraction(this.selectForDeletion);
      this.selectForDeletion = undefined;
    }
    if (this.selectForUpdate) {
      this.removeSelectInteraction(this.selectForUpdate);
      this.selectForUpdate = undefined;
    }
    if (this.selectForTranslate) {
      this.removeSelectInteraction(this.selectForTranslate);
      this.selectForTranslate = undefined;
    }
    if (this.modify) {
      this.modify.setActive(false);
      this.mapService.map.removeInteraction(this.modify);
      this.modify = undefined;
    }
    if (this.translate) {
      this.translate.setActive(false);
      this.mapService.map.removeInteraction(this.translate);
      this.translate = undefined;
    }
  }

  public removeSelectInteraction(interaction: Select): void {
    interaction.getFeatures().clear();
    interaction.setActive(false);
    this.mapService.map.removeInteraction(interaction);
  }

  public reset(): void {
    EventBus.$emit(EVENTS.ENABLE_SELECTION);
    this.activeTool = "";
    this.removeInteractions();
  }

  public completeOLStyleWithFeatureStyle(olStyle: Style, featureStyle:any): Style {
    
      olStyle.getFill()!.setColor(featureStyle.fillColor);
      olStyle.getText()!.setFont(featureStyle.fontSize + this.fontPostFix);
  
      // cannot change image color once created.
      // change it from scratch
      // https://github.com/openlayers/openlayers/issues/7398
  
      olStyle.setImage(new Circle({
        radius: featureStyle.pointRadius,
        fill: new Fill({
          color: featureStyle.pointColor
        })
      }));
  
      olStyle.getStroke()!.setColor(featureStyle.strokeColor);
      olStyle.getStroke()!.setWidth(featureStyle.strokeWidth);
      olStyle.getText()!.getFill()!.setColor(featureStyle.textColor);
      olStyle.getText()!.setText(featureStyle.text);
   
    return olStyle;
  }

  private addEdition(type: Type, layer: LayerConfig): void {
    if (this.draw) {
      this.mapService.map.removeInteraction(this.draw);
    }
    this.draw = new Draw({
      source: this.vectorSource,
      type: type,
      style: this.drawStyle,
      geometryName: layer.getGeomAttributeName()
    });
    this.mapService.map.addInteraction(this.draw);

    let self = this;
    this.draw.on('drawend', (evt: any) => {
      var feature: Feature<any> = evt.feature;
      //feature.set('style', JSON.stringify(this.editionsService.currentStyle));

      // WFS ne peut enregistrer de cercle. On utilise OpenLayer pour le convertir en Polygone
      if (type == "Circle") {
        let circle:any = feature.getGeometry();
        let polygonFromCircle: any = fromCircle(circle);
        let guessFetureType = this.guessFeatureType('Polygon')
        if (guessFetureType == 'MultiPolygon')
          polygonFromCircle = new MultiPolygon([polygonFromCircle.getCoordinates()], polygonFromCircle.getLayout())
        feature.setGeometry(polygonFromCircle);
        console.log(polygonFromCircle);
      }

    });



    this.listenerKey = this.vectorSource.on('addfeature', (evt: any) => {
      if (layer.type == 'WFS' && self.vectorSource) {
        self.updateFeature(evt.feature, layer, "insert");
      }
      else {
        self.save(layer);
      }
    });


  }


  save(layer:LayerConfig) {
    if (this.vectorSource) {
      let feats = this.vectorSource.getFeatures();
      console.log("nombre d'objets a sauver:" + feats.length)
      var featobj = (new GeoJSON()).writeFeaturesObject(feats,{decimals:2,});
      featobj.crs= { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3857" } };
      const featstring=JSON.stringify(featobj);
      let sizeInko = Number(featstring.length / 1024);
      if (sizeInko > 500) {
        layer.addError("Trop de données dans cette couche pour la sauvegarde( " + sizeInko + " ko), vous devez supprimer des objets");
        throw new Error("TooMuchDatas");
      }
      else {
        //let layerInConfig = config.LAYERS.find(x => x.nom_court == layer.title);
        //if (layerInConfig)
          layer["jsonData"] = featstring;
      }

    }

    // Maybe send a save to server

  }

}