/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import LeftSideBar from '@/components/LeftSideBar.vue';
//import Dataviz from '@/components/dataviz/Dataviz.vue';
import EditionToolBar from '@/components/map/EditionToolBar.vue';
import FeatureInfosPanel from '@/components/map/FeatureInfosPanel.vue';
import MapPluginContainer from '@/components/map/MapPluginContainer.vue';
import PhotomaillageToolBar from '@/components/map/PhotomaillageToolBar.vue';
import LayerConfig from '@/models/map-context/LayerConfig';
import { EventBus, EVENTS } from '@/services/EventBus';
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';

@Component({
  props: {
    msg: String,
  },
  components: {
    MapPluginContainer,
    EditionToolBar,
    LeftSideBar,
    //Dataviz,
    FeatureInfosPanel,
    PhotomaillageToolBar,
  },
})
export default class Map extends BaseComponent {
  msg!: string;

  editedLayer:LayerConfig|null=null;

  editedPhotoLayer:LayerConfig|null=null;

  cesiumMap = true;

  mounted() {
    this.cesiumMap = this.is3DView();

    console.log('loading map component');
    EventBus.$on(EVENTS.MAP_INIT_END, () => this.afterMapInit());
    EventBus.$on(EVENTS.MAP_CURRENT_BBOX_CHANGE, (bbox:any) => this.onMoveEnd(bbox));
    this.pluginService.loadPlugins(this.contextService.getCurrentContext().plugins);
    if(this.cesiumMap){
      this.getMapService().initMap(this.contextService.getCurrentContext(), this.$refs.cesiumMap,this.$refs.popup, this.debug);
    }else{
      this.getMapService().initMap(this.contextService.getCurrentContext(), this.$refs.main_map,this.$refs.popup, this.debug);
    }
    
    EventBus.$on(EVENTS.START_EDITION, (layer:LayerConfig) => this.onEditStart(layer));
    EventBus.$on(EVENTS.STOP_EDITION, (layer:LayerConfig) => this.onEditStop(layer));

    EventBus.$on(EVENTS.START_PHOTOMAILLAGE_EDITION, (layer:LayerConfig) => this.onEditPhotomaillageStart(layer));
    EventBus.$on(EVENTS.STOP_PHOTOMAILLAGE_EDITION, (layer:LayerConfig) => this.onEditPhotomaillageStop(layer));
  }

  movable(): boolean {
    return this.pluginService.isActive('FeatureInfosPanel') && this.pluginService.getBooleanParameter('FeatureInfosPanel', 'movable');
  }

  onMoveEnd(bbox:any) {
    // this.$route.query.bbox=bbox[0]+","+bbox[1]+","+bbox[2]+","+bbox[3];
    const url = new URL(window.location.href);
    console.log(url);
    const hash = window.location.hash.substr(1);
    const hashSplitted = window.location.hash.substr(1).split('?');
    let hashParams:any = {};
    if (hashSplitted.length > 1) {
      hashParams = hashSplitted[1].split('&').reduce((res:any, item) => {
        const parts = item.split('=');
        res[parts[0]] = parts[1];
        return res;
      }, {});
    }

    hashParams.bbox = `${bbox[0]},${bbox[1]},${bbox[2]},${bbox[3]}`;
    console.log(hashParams);
    const stringHashParams = Object.keys(hashParams).map((key) => [key, hashParams[key]].join('=')).join('&');
    url.hash = `#${hashSplitted[0]}?${stringHashParams}`;

    window.history.replaceState('', '', url.href);
  }

  afterMapInit() {
    if (this.$route) {
      const { bbox, camera_pos } = this.$route.query;

      if (this.is3DView()
      && camera_pos !== undefined
      && camera_pos !== null
      && !Array.isArray(camera_pos)) {
      const camera_posArray:number[] = camera_pos.split(',').map((x) => parseFloat(x));
      const heading:any = this.$route.query.camera_heading;
      const pitch:any = this.$route.query.camera_pitch;
      const roll:any = this.$route.query.camera_roll;
      this.getCesiumMapService().setCameraView(camera_posArray,
        parseFloat(heading), parseFloat(pitch), parseFloat(roll));
    } else if (bbox !== undefined && bbox !== null && !Array.isArray(bbox)) {
      const bboxWGS84 = bbox.split(',');
      const numberbboxWGS84:number[] = bboxWGS84.map((x) => parseFloat(x));
      this.getMapService().zoomToBboxWGS84(numberbboxWGS84);
    }
    const { addLayer } = this.$route.query;
    if (addLayer !== undefined && !Array.isArray(addLayer) && addLayer.length > 0) {
      const layerConfig = JSON.parse(atob(addLayer));
      let bboxWGS = {
        minx: -179, maxx: 179, miny: -89, maxy: 89,
      };
      let zoomTo = false;
      if (layerConfig.bbox_by_projection) {
        bboxWGS = layerConfig.bbox_by_projection['EPSG:4326'];
        zoomTo = true;
      }
      
      let copyright:any='';
      if(layerConfig.copyright && layerConfig.copyright.length>0){
        copyright={ Title: layerConfig.copyright} ;
      }
      const newLayer = new LayerConfig({
        layername: layerConfig.name,
        url: layerConfig.url,
        title: layerConfig.description,
        description: layerConfig.description,
        type: 'WMS',
        Attribution:copyright,
        outputFormat: layerConfig.formats[0],
        visible: true,
        // projection: layerConfig.projections[0],
        boundingBoxEPSG4326: [bboxWGS.minx, bboxWGS.miny, bboxWGS.maxx, bboxWGS.maxy],
      });
      console.log(layerConfig);
      this.addLayerToMap(newLayer);
      if (zoomTo) {
        this.getMapService().zoomToLayer(newLayer);
      }
    }
  }
  }

  private addLayerToMap(newLayer: LayerConfig) {
    try {
      this.contextService.getCurrentContext().getLayers().unshift(newLayer);
      this.getMapService().addLayer(newLayer);
    } catch (error) {
      console.error(error);
    }
  }

  beforeDestroy() {
    this.getMapService().destroyMap();
    this.getMapService().selectionService.selectedFeature=null;
    this.contextService.currentContext = null;
    this.contextService.currentApplication = null;
    EventBus.$off(EVENTS.MAP_INIT_END);
    EventBus.$off(EVENTS.MAP_CURRENT_BBOX_CHANGE);
    EventBus.$off(EVENTS.START_EDITION);
    EventBus.$off(EVENTS.STOP_EDITION);
    EventBus.$off(EVENTS.START_PHOTOMAILLAGE_EDITION);
    EventBus.$off(EVENTS.STOP_PHOTOMAILLAGE_EDITION);
    EventBus.$off(EVENTS.SELECT_FEATURE);
    EventBus.$off(EVENTS.PLUGIN_ACTIVATION_CHANGE);
    EventBus.$off(EVENTS.ERROR);
    EventBus.$off(EVENTS.INFO);
    EventBus.$off(EVENTS.WARNING);
    EventBus.$off(EVENTS.NOTIFICATION);
    EventBus.$off(EVENTS.OPEN_LEFT_SIDE_MENU);
  }

  onEditStart(layer:LayerConfig) :void{
    this.editedLayer = layer;
  }

  onEditStop(layer:LayerConfig) {
    this.editedLayer = null;
  }

  onEditPhotomaillageStart(layer:LayerConfig) :void{
    this.editedPhotoLayer = layer;
  }

  onEditPhotomaillageStop(layer:LayerConfig) {
    this.editedPhotoLayer = null;
  }
}
