/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
import {
  Observable, of, pipe, from, forkJoin,
} from 'rxjs';
import {
  catchError,
  map,
  shareReplay,
} from 'rxjs/operators';
import { contexteServiceInstance, ContextService } from '@/services/ContextService';
import CapabilitiesParser from '../models/CapabilitiesParser';
import CapabilitiesResponse from '@/models/CapabilitiesResponse';

class CapabilitiesService {
  // cache des reponses aux appels des getcapabilities
  wmtsCapabilitiesCache: any = {};

  wmtsCapabilitiesPendingRequest: any = {};

  contextService=contexteServiceInstance;

  parser = new CapabilitiesParser();

  getRecords(url: string, headers: any = {}): Observable<any> {
    return from(axios.get(url, { headers, responseType: 'text' })).pipe(
      catchError((error) => {
        console.log(error);
        if (error.message === 'Network Error' && url.indexOf('/proxy') < 0) {
          console.log('Retry with proxy');
          console.log('Error:retry with proxy');
          const proxyfiedUrl = this.contextService.getProxyFiedUrl(url);
          return from(axios.get(proxyfiedUrl, { headers, responseType: 'text' })).pipe(
            map((reponse:any) => {
              console.log(reponse);
              reponse.useProxy = true;
              return reponse;
            }),
          );
        }
        return of(`I caught: ${error}`);
      }),
    ).pipe(
      map((reponse:any) => {
        console.log(reponse);
        const capabilities = this.parser.parseRecords(reponse.data, url);
        capabilities.useProxy = reponse.useProxy;
        return capabilities;
      }),
    );
  }

  getCapabilities(url: string, headers: any = {}, usecache = true): Observable<any> {
    if (usecache && this.wmtsCapabilitiesCache[url]) {
      return of(this.wmtsCapabilitiesCache[url]);
    }
    if (this.wmtsCapabilitiesPendingRequest[url]) {
      console.log(`reuse observable for ${url}`);
      return this.wmtsCapabilitiesPendingRequest[url];
    }
    console.log(`create observable for ${url}`);
    this.wmtsCapabilitiesPendingRequest[url] = from(axios.get(url, { headers, responseType: 'text' })).pipe(
      catchError((error) => {
        console.log(error);
        this.wmtsCapabilitiesPendingRequest[url] = undefined;
        if (error.message === 'Network Error' && url.indexOf('/proxy') < 0) {
          console.log('Retry with proxy');
          console.log('Error:retry with proxy');
          const proxyfiedUrl = this.contextService.getProxyFiedUrl(url);
          return from(axios.get(proxyfiedUrl, { headers, responseType: 'text' }))
           .pipe( 
            map((reponse:any) => {
              console.log(reponse);
              reponse.useProxy = true;
              return reponse;
            }),
          ).pipe(
            catchError((error) => {
              console.error(error);
            throw new Error('Erreur CORS puis erreur avec le proxy <br>'+proxyfiedUrl+'<br>'+error.message);   
          }));
        }
        return of(`I caught: ${error}`);
      }),
    ).pipe(
      map((reponse:any) => {
        console.log(reponse);
        this.wmtsCapabilitiesPendingRequest[url] = undefined;
        const capabilities = new CapabilitiesResponse(this.parser.parse(reponse.data, url));
        capabilities.useProxy = reponse.useProxy;
        this.wmtsCapabilitiesCache[url] = capabilities;
        return capabilities;
      }),
      shareReplay(1),
    );
    return this.wmtsCapabilitiesPendingRequest[url];
  }

  parseWMC(data: any):Observable<any> {
    const parser = new CapabilitiesParser();
    const appConfig = parser.parseWMC(data);
    const calls:Observable<any>[] = [];
    appConfig.layers.forEach((layer:any) => {
      const currentLayer = layer;
      if (currentLayer.type === 'WMS') {
        const capabilitiesObservable = this.getCapabilities(this.getCapabilitiesUrl(layer.url))
          .pipe(map((response:any) => {
            console.log(response);

            const selectedLayer = response.layers
              .find((x:any) => x.Name === currentLayer.layername);
            if (selectedLayer) {
              const bbox = selectedLayer.EX_GeographicBoundingBox;
              for (let i = 0; i < bbox.length; i += 1) {
                bbox[i] = +bbox[i].toFixed(3);
              }
              currentLayer.boundingBoxEPSG4326 = bbox;
            }
            return appConfig;
          }))
          .pipe(
            catchError((err) => of(appConfig)),
          );
        calls.push(capabilitiesObservable);
      }
    });
    // wait for all observable and send back first response as
    // they are all the same

    return forkJoin(calls).pipe(map((response:any) => response[0]));
  }

  getCapabilitiesUrl(url:string): string {
    const searchMask = 'getCapabilities';
    const regEx = new RegExp(searchMask, 'ig');
    if (regEx.test(url)) {
      return url;
    }
    return `${url}&request=getCapabilities`;
  }
}
const capabilitiesServiceInstance = new CapabilitiesService();
export default CapabilitiesService;
export { CapabilitiesService, capabilitiesServiceInstance };
