


























import LayerConfig from '@/models/map-context/LayerConfig';
import BaseComponent from '../BaseComponent';
import Component from 'vue-class-component';

@Component({
  props: {
    features: {},
    id: String,
    visible: Boolean,
    layerOfFeature: LayerConfig,
  },
  components: {
  },
})
export default class Accordion extends BaseComponent {
  features: any;

  id!: String;

  visible!: Boolean;

  layerOfFeature!: LayerConfig;

  data() {
    return {
      layerOfFeature: {},
      features: {}
    }
  }

  // mounted() {
  //   this.layerOfFeature = this.layerOfFeature;
  // }

  getLabel(key:any) {
    if (this.layerOfFeature && this.layerOfFeature.layerProperties) {
      const searchAttribute = this.layerOfFeature.layerProperties.filter((data) => data.name === key)[0];
      if (searchAttribute !== undefined && searchAttribute.label !== undefined && searchAttribute.label !== '') {
        return searchAttribute.label;
      }
    }

    return key;
  }

  isUrl(value: any) {
      return typeof value === 'string' && (value.startsWith('http://') || value.startsWith('https://'));
  }
}
