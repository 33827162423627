









































































































import GroupLayerSimplified from '@/components/layers/GroupLayerSimplified.vue';
import SingleLayerElementSimplified from '@/components/layers/SingleLayerElementSimplified.vue';
import Component from 'vue-class-component';
import PluginComponent from './PluginComponent';
import axios from 'axios';
import LayerConfig from '@/models/map-context/LayerConfig';

@Component({
  props: {},
  components: {
    GroupLayerSimplified,
    SingleLayerElementSimplified,
  },
})
export default class SPSEPlugin extends PluginComponent {
  static ID = 'SPSEPlugin';

  currentFeature: any = null;

  spse_url: string = '';

  currentDay = '';

  valueCurrentDay = '';

  currentMonth = '';

  valueCurrentMonth = '';

  currentYear = '';

  hovered:boolean =  false;

  activeButton: string = 'period';

  valueCurrentYear = '';

  showAddLayerBtn = true;

  startDate: Date | null = new Date();

  endDate: Date | null = new Date() ;

  data: any[] = [];

  created() {
    this.activeButton = 'period';

    this.spse_url = this.plugin.getParameterValue('url_spse');

    this.currentDay = this.getCurrentDate();
   
    this.setValueCurrentDate();

    this.currentMonth = this.getCurrentMonth() + this.getCurrentYear();

    this.currentYear = this.getCurrentYear();

    this.valueCurrentYear = this.getValueCurrentYear();

    this.setValueCurrentDate();

    this.addPeriodeLayer();

    this.loadReportData()

  }

  mounted() {
    this.spse_url = this.plugin.getParameterValue('url_spse');
    this.loadReportData()
  }

  getCurrentDate(): string {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  getCurrentMonth(): string {
    const date = new Date();
    let month = date.getMonth();
    var output = 'Rapport ';
    switch (month) {
      case 0:
        'Janvier ';
        break;
      case 1:
        output += 'Fevrier ';
        break;
      case 2:
        output += 'Mars ';
        break;
      case 3:
        output += 'Avril ';
        break;
      case 4:
        output += 'Mai ';
        break;
      case 5:
        output += 'Juin ';
        break;
      case 6:
        output += 'Juillet ';
        break;
      case 7:
        output += 'Août ';
        break;
      case 8:
        output += 'Septembre ';
        break;
      case 9:
        output += 'Octobre ';
        break;
      case 10:
        output += 'Novembre ';
        break;
      case 11:
        output += 'Decembre ';
        break;
      default:
        break;
    }
    return `${output}`;
  }

  getCurrentYear(): string {
    const date = new Date();
    let year = date.getFullYear();
    return `${year}`;
  }

  setValueCurrentDate(): void {
    const todayStart = new Date(new Date().setHours(0, 0, 0, 0));
    const todayEnd = new Date(new Date().setHours(23, 59, 59, 0));
    this.startDate = todayStart
    this.endDate = todayEnd
  }

  setValueCurrentMonth(): void {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
      this.startDate = new Date(y, m, 1, 0, 0, 0);
      this.endDate = new Date(y, m + 1, 0, 23, 59, 59);
  }

  setValueCurrentYear(): void {
    var date = new Date(),
        y = date.getFullYear();
    this.startDate = new Date(y, 0, 1, 0, 0, 0);
    this.endDate = new Date(y, 11, 31, 23, 59, 59);
  }

  loadReportData() {
    this.getReportService().then((data) => {
      this.data = data;
    });
  }

  validateDate(startDate: Date | null, endDate: Date | null): boolean | null {
      if (!startDate || !endDate) return null;
      const start = new Date(startDate);
      const end = new Date(endDate);
      return start <= end;
  }

  downloadFile(item: any) {
    fetch(`${this.spse_url}${item.export_url}`, {
      credentials: 'include',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        start: this.formatDateToCustom(this.startDate!),
        end: this.formatDateToCustom(this.endDate!)
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then(blob => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${item.filename}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(error => {
      console.error('Error downloading the file:', error);
    });
  }

  getReportService(): Promise<any> {
    console.log('formatDateToCustom', this.spse_url )
    return fetch(this.spse_url + 'api/visits/get-report/', {
      credentials: 'include',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        start: this.formatDateToCustom(this.startDate!),
        end: this.formatDateToCustom(this.endDate!)
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      console.error("Error fetching report:", error);
      return {"error": "No Data"};
    });
  }


  reportMonth(): void {
    this.activeButton = 'month'
    this.setValueCurrentMonth()
    this.addPeriodeLayer()
    this.loadReportData()
  }

  reportYear(): void {
    this.activeButton = 'year'
    this.setValueCurrentYear()
    this.addPeriodeLayer()
    this.loadReportData()
  }

  reportPeriod(): void {
    this.activeButton = 'period'
    this.setValueCurrentDate()
    this.addPeriodeLayer()
    this.loadReportData()
  }

  reportSelectPeriod(): void {
    this.activeButton = 'period'
    this.addPeriodeLayer()
    this.loadReportData()
  }

  formatDateToCustom(date: Date): string {
    const dateCustom = new Date(date)
    const padZero = (num: any) => num.toString().padStart(2, '0');

    const year = dateCustom.getFullYear();
    const month = padZero(dateCustom.getMonth() + 1);
    const day = padZero(dateCustom.getDate());
    const hours = padZero(dateCustom.getHours());
    const minutes = padZero(dateCustom.getMinutes());
    const seconds = padZero(dateCustom.getSeconds());

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  }

  updateLayerActived(layerConfig:any) {
    const retour = this.getMapService().updateLayer(layerConfig);
    if (!retour.success) {
      layerConfig.error = retour.message ;
    } else {
      this.contextService.setCurrentAction('', []);
    }
  
  }

  addPeriodeLayer() {
      let lstLayer  = this.contextService.getCurrentContext().getLayers()
      const start = new Date(this.startDate!);
      const end = new Date(this.endDate!);
      const time = `${start?.toJSON()}/${end?.toJSON()}`

      for (let i=0; i<lstLayer.length; i+=1){
        this.setTimeLayer(lstLayer[i], time)
        if(lstLayer[i].visible){
          if(lstLayer[i].childs && lstLayer[i].childs?.length > 0){
            lstLayer[i].childs.map((layer : LayerConfig) =>{
              this.updateLayerActived(layer)
            })
          }else{
            this.updateLayerActived(lstLayer[i])
          }
        }
        
      }
    }

  setTimeLayer(layerConfig: any, time:string){
    // layerConfig.setDateStart(dateStart);
    // layerConfig.setDateEnd(dateEnd);
    if(layerConfig.childs && layerConfig.childs?.length > 0){
      layerConfig.childs.map((layer : LayerConfig) =>{
        layer.setTimeValue(time)
        this.getMapService().updateLayer(layer);
      })
    }else{
      layerConfig.setTimeValue(time)
      this.getMapService().updateLayer(layerConfig);
    }
  }

  getValueCurrentYear(): string {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, 0, 1);
    var lastDay = new Date(y, 12, 1);
    return `${firstDay.toJSON()}/${lastDay.toJSON()}`;
  }


}
