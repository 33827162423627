




























































/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import EditionService from '@/services/EditionService';
import { EventBus, EVENTS } from '@/services/EventBus';
import { MapService } from '@/services/MapService';
import Component from 'vue-class-component';
import PluginComponent from '../plugins/PluginComponent';


@Component({
  props: {},
  components: {},
})
export default class DataVizPlugin extends PluginComponent {
  static ID = 'DataVizPlugin';

  currentFeature: any = null;

  edition = false;

  editionService!: EditionService;

  metabase_url: any = '';

  currentDay = '';

  valueCurrentDay = '';

  currentMonth = '';

  valueCurrentMonth = '';

  currentYear = '';

  valueCurrentYear = '';

  // indicator = document.querySelector('.loading-indicator');
  ifram: any = null;

  created() {
    const mapService = this.getMapService();
    if (mapService instanceof MapService) {
      this.editionService = mapService.editionService;
    }

    this.metabase_url = this.plugin.getParameterValue('url_dashboard');

    this.currentDay = this.getCurrentDate();
   
    this.valueCurrentDay = this.getValueCurrentDate();

    this.currentMonth = this.getCurrentMonth() + this.getCurrentYear();
 
    this.valueCurrentMonth = this.getValueCurrentMonth();

    this.currentYear = this.getCurrentYear();

    this.valueCurrentYear = this.getValueCurrentYear();

    this.ifram = document.getElementById('iframe_id');
  }

  mounted() {
  }

  closePanel() {
    this.currentFeature = null;
    EventBus.$emit(EVENTS.OPEN_LEFT_SIDE_MENU, '');
  }

  cancel(): void {
    this.edition = false;
  }

  getCurrentDate(): string {
    // let currentDate = new Date().toJSON().slice(0, 10);
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let currentDate;

    if (day.toString().length == 1) {
      if (month.toString().length == 1) {
        currentDate = `0${day}/0${month}/${year}`;
      } else {
        currentDate = `0${day}/${month}/${year}`;
      }
    } else {
      if (month.toString().length == 1) {
        currentDate = `${day}/0${month}/${year}`;
      } else {
        currentDate = `${day}/${month}/${year}`;
      }
    }
    // let currentDate = `${day}-${month}-${year}`;
    return `${currentDate}`;
  }

  getValueCurrentDate(): string {
    // const date = new Date()
    const date = new Date(new Date().setHours(0, 0, 0, 0));
    // console.log(date);

    const cuurentDate = date;
    const today = cuurentDate.toJSON();

    const dateCopy = new Date(date);
    dateCopy.setDate(dateCopy.getDate() + 1);
    const tomorrow = dateCopy.toJSON();

    // console.log("CURRENT DAY: ",`${today}/${tomorrow}`);

    return `${today}/${tomorrow}`;
  }

  getCurrentMonth(): string {
    const date = new Date();
    let month = date.getMonth();
    var output = 'Rapport ';
    switch (month) {
      case 0:
        output += 'Janvier ';
        break;
      case 1:
        output += 'Fevrier ';
        break;
      case 2:
        output += 'Mars ';
        break;
      case 3:
        output += 'Avril ';
        break;
      case 4:
        output += 'Mai ';
        break;
      case 5:
        output += 'Juin ';
        break;
      case 6:
        output += 'Juillet ';
        break;
      case 7:
        output += 'Août ';
        break;
      case 8:
        output += 'Septembre ';
        break;
      case 9:
        output += 'Octobre ';
        break;
      case 10:
        output += 'Novembre ';
        break;
      case 11:
        output += 'Decembre ';
        break;
      default:
        break;
    }
    // console.log("CURRENT MONTH: ",`${output}`);
    return `${output}`;
  }

  getValueCurrentMonth(): string {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 1);
    return `${firstDay.toJSON()}/${lastDay.toJSON()}`;
  }

  getCurrentYear(): string {
    const date = new Date();
    let year = date.getFullYear();
    // console.log("CURRENT YEAR: ",`${year}`);

    return `${year}`;
  }

  getValueCurrentYear(): string {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, 0, 1);
    var lastDay = new Date(y, 12, 1);
    return `${firstDay.toJSON()}/${lastDay.toJSON()}`;
  }

  updateLayerActived(layerConfig: any) {
    // console.log("LAYER UP: ",layerConfig);

    const retour = this.getMapService().updateLayer(layerConfig);

    if (!retour.success) {
      console.log('error: ', retour.message);
      layerConfig.error = retour.message;
    } else {
      this.contextService.setCurrentAction('', []);
    }
  }

  setTimeLayer(layerConfig: any, time: string) {
    // layerConfig.setDateStart(dateStart);
    // layerConfig.setDateEnd(dateEnd);
    layerConfig.setTimeValue(time);
    this.getMapService().updateLayer(layerConfig);
  }
  
  periode(time: string) {
    const dateIndex = this.metabase_url.search('&intervalle_de_dates=');
    if (dateIndex != -1) {
      const deleteDateFilter = this.metabase_url.slice(0, parseInt(dateIndex));
      this.metabase_url = deleteDateFilter;
    }

    const dateMetabase = time.split('/');
    const dateDebut = dateMetabase[0].slice(0, 10);
    const dateFin = dateMetabase[1].slice(0, 10);

    const newUrlMetabase = this.metabase_url.concat(
      '&intervalle_de_dates=',
      dateDebut,
      '~',
      dateFin
    );
    this.metabase_url = newUrlMetabase;
  
    let lstLayer = this.contextService.getCurrentContext().getLayers();

    for (let i = 0; i < lstLayer.length; i += 1) {
      this.setTimeLayer(lstLayer[i], time);

      if (lstLayer[i].visible) {
        this.updateLayerActived(lstLayer[i]);
      }
    }
  }

}
