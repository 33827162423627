/* eslint-disable max-len */
/* eslint-disable no-param-reassign */

import { PluginJsonConfig } from '../plugin/PluginJsonConfig';
import AbstractLayerTreeElement from './AbstractLayerTreeElement';
import { IDashBoardTile } from './DashBoardTile';
import LayerConfig from './LayerConfig';
import LayerGroupConfig from './LayerGroupConfig';
import { EventBus, EVENTS } from '@/services/EventBus';

export default class MapContext {
  version = 1.0;

  globalParameters: any = {};

  cesiumParameters: any = {
    timeline: false,
    shadows: false,
    fxaa: false,
    fog: false,
    angles: [],
  };

  coords!: number[];

  zoom = 0;

  projection!: string;

  presentation!: string;

  showPresentation = false;

  bbox!:any;

  public dashboard: IDashBoardTile[]= []

  public layers: AbstractLayerTreeElement[] = [];

  public baseLayers : LayerConfig[] = [];

  public groupLayers : LayerConfig[] = [];

  public plugins!: PluginJsonConfig[];

  constructor(input?: unknown) {
    console.log(input);
    if (input) {
      this.checkValues(input);
      Object.assign(this, input);
    } else {
      throw Error('Paramètres manquants pour la création du contexte');
    }

    for (let i = 0; i < this.layers.length; i += 1) {
      // eslint-disable-next-line keyword-spacing
      if ((<any>this.layers[i]).childs !== undefined) {
        this.layers[i] = new LayerGroupConfig(this.layers[i]);
      } else {
        this.layers[i] = new LayerConfig(this.layers[i]);
      }
    }
    if (!this.cesiumParameters.angles || this.cesiumParameters.angles.length === 0) {
      this.cesiumParameters.angles = [];
      this.cesiumParameters.angles[0] = 0.0;
      this.cesiumParameters.angles[1] = -89.0;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  checkValues(input:any) :void{
    if (input.version === undefined) {
      throw Error('Format de carte inconnu, il devrait contenir un champ version');
    }
    if (input.projection === undefined) {
      throw Error('Projection non définie');
    }
  }

  getLayers(): AbstractLayerTreeElement[] {
    console.log(this.layers);
    return this.layers;
  }

  getLayerById(id:string): LayerConfig|undefined {
    return this.getOrderedLayerList().find((x) => x.id == id);
  }

  flatten(arr: AbstractLayerTreeElement[]): LayerConfig[] {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return arr.reduce(function (flat: LayerConfig[], toFlatten) {
      const toConcat: LayerConfig[] = toFlatten instanceof LayerGroupConfig ? self.flatten(toFlatten.childs) : [<LayerConfig>toFlatten];
      return flat.concat(toConcat);
    }, []);
  }

  /**
   * Retourne la liste à plat et ordonnées des couches (noeuds enfants) et non la liste arborescente avec les groupes de couches
   */
  getOrderedLayerList(): LayerConfig[] {
    return this.flatten(this.layers);
  }

  getOrderedLayerGroupList(): AbstractLayerTreeElement[] {
    return this.layers
  }

  removeLayerFromList(layer: AbstractLayerTreeElement): void {
    // remove from current location
    if (layer.parent) {
      const indexStart = layer.parent.childs.indexOf(layer);
      layer.parent.childs.splice(indexStart, 1);
    } else {
      const indexStart = this.layers.indexOf(layer);
      this.layers.splice(indexStart, 1);
    }
  }

  getBackgroundGroup(): LayerGroupConfig {
    // eslint-disable-next-line keyword-spacing
    return (<LayerGroupConfig>this.layers.filter((cat) => cat instanceof LayerGroupConfig && cat.idGroup < 0)[0]);
  }

  changeLayerOrder(source: AbstractLayerTreeElement, target: AbstractLayerTreeElement, location: string): void {
    // remove from current location
    this.removeLayerFromList(source);

    // insert at target location
    // Drop before
    if (location === 'before') {
      if (target.parent) {
        const indexDest = target.parent.childs.indexOf(target);
        target.parent.childs.splice(indexDest, 0, source);
        // update parent
        source.parent = target.parent;
      } else {
        const indexDest = this.layers.indexOf(target);
        this.layers.splice(indexDest, 0, source);
        // update parent
        source.parent = undefined;
      }
    } else if (location === 'after') { // Drop after
      if (target.parent) {
        const indexDest = target.parent.childs.indexOf(target);
        target.parent.childs.splice(indexDest + 1, 0, source);
        // update parent
        source.parent = target.parent;
      } else {
        const indexDest = this.layers.indexOf(target);
        this.layers.splice(indexDest + 1, 0, source);
        // update parent
        source.parent = undefined;
      }
    } else if (location === 'into' && target instanceof LayerGroupConfig) { // Drop into
      target.childs.unshift(source);
      // update parent
      source.parent = target;
    }
    EventBus.$emit(EVENTS.LAYER_ORDER_CHANGE);
  }

  toJson(): any {
    const retour = {
      version: this.version,
      globalParameters: this.globalParameters,
      presentation: this.presentation,
      showPresentation: this.showPresentation,
      cesiumParameters: this.cesiumParameters,
      coords: this.coords,
      zoom: this.zoom,
      projection: this.projection,
      layers: <any>[],
      plugins: <any>[],
      dashboard: this.dashboard,
    };
    this.layers.forEach((layer) => retour.layers.push(layer.toJson()));
    return retour;
  }
}
