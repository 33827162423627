




































































import { Component, Vue } from 'vue-property-decorator';
import PluginComponent from './PluginComponent';
import { EventBus, EVENTS } from '@/services/EventBus';
import LayerConfig from '@/models/map-context/LayerConfig';
import Accordion from '../map/Accordion.vue';
import AbstractSelectionService from '@/services/AbstractSelectionService';
import axios from 'axios';

@Component<FeatureInfosPanelCustom>({
  props: {
  },
  components: {
    Accordion,
  },
})

export default class FeatureInfosPanelCustom extends PluginComponent {
  static ID='FeatureInfosPanelCustom';

  static NAME='FeatureInfosPanelCustom';

  private currentFeature:any;

  isFeatureSelected = false;

  public layerOfFeature: LayerConfig|null=null;

  edition = false;

  showList=false;

  featureIndex = 0;

  infosContent = '';

  siteFeature : any = [];

  buildingFeature : any= [];

  plotsFeature : any= [];

  acte_propriete_file: any = null;
  
  currentPage: string = 'Site';

  openOnSelectedFeature(features:any):boolean{
    console.log(features)
    console.log(features)
    if(features.length>0 && (features[0].layerConfig.layername=='PG:maps_sites'
    || features[0].layerConfig.layername=='PG:maps_batiments'
    || features[0].layerConfig.layername=='PG:maps_parcelles'
    )){
      return true
    }
    return false;
  }

  


  beforeDestroy():void {
    EventBus.$off(EVENTS.SELECT_FEATURE, this.onFeatureSelected);
  }

  created() {  
  }

  mounted() {
    EventBus.$on(EVENTS.SELECT_FEATURE, this.onFeatureSelected);
    const selectedFeatures = this.getMapService().selectionService.selectedFeature;
    if (selectedFeatures !== undefined) {
      this.onFeatureSelected(selectedFeatures);
    }
    
  }


  onFeatureSelected(features: any) {
      this.siteFeature = [];
      this.buildingFeature = [];
      this.plotsFeature = [];
      this.featureIndex = 0;
      this.currentFeature = features[this.featureIndex];
      this.isFeatureSelected = true;

      if(this.currentFeature){
        if( this.currentFeature.id_.startsWith("maps_batiments")){
          this.currentPage = 'Construction';
          this.buildingFeature = [this.currentFeature];
        }else if (this.currentFeature.id_.startsWith("maps_parcelles")){
          this.currentPage = 'Foncier';
          this.plotsFeature =  [this.currentFeature];
        }else if (this.currentFeature.id_.startsWith("maps_sites")){
          this.siteFeature = this.currentFeature;
          this.currentPage = 'Site';
        }
      }else {
        // reset le panneau de modification
        this.currentFeature = null;
        this.layerOfFeature = null;
        this.isFeatureSelected = false;
      }
      this.getMapService().selectionService.addToSelection(this.currentFeature);
      this.getFeatureOnWfs()
  }

  async getFeatureOnWfs(){
    const regex = /^(.+)\.(\d+)$/;
    if(Object.keys( this.siteFeature).length > 0 && 
        Object.keys( this.plotsFeature).length <= 0 && 
         Object.keys( this.buildingFeature).length <= 0)
    {

      const params = this.siteFeature.id_.match(regex)
      const dataPlots: any = await this.getFeatureWfs( `id_site%3D'${params[2]}'`, 'PG:maps_parcelles',this.currentFeature.layerConfig.url)
      const dataBuilding: any = await this.getFeatureWfs( `id_site%3D'${params[2]}'`, 'PG:maps_batiments',this.currentFeature.layerConfig.url)
      this.plotsFeature = dataPlots.features
      this.buildingFeature = dataBuilding.features
    } else if (Object.keys( this.siteFeature).length > 0 && 
                Object.keys( this.plotsFeature).length == 1 && 
                  Object.keys( this.buildingFeature).length <= 0)
    {
      const params = this.plotsFeature[0].id_.match(regex)
      const data: any = await this.getFeatureWfs( `parc_id%3D'${params[2]}'`, 'PG:maps_batiments',this.currentFeature.layerConfig.url)
      this.buildingFeature = data.features
    }
    this.layerOfFeature = this.siteFeature.layerConfig;
  }
  
  public async getFeatureWfs( filter: String, feature: String, headerUrl: string){
      const featureUrl = headerUrl.split('?')
      // const layerNameRegex = new RegExp(`^PG:${feature}$`, 'i');
      const url = `${featureUrl[0]}?service=wfs&version=2.0.0&REQUEST=GetFeature&typeNames=${feature}&outputformat=json&srsName=epsg:3857&cql_filter=${filter}`
      try {
        const res: any = await axios.get(url);

        const filteredLayer = this.getMapService().getOrderedLayerList().find(layer => feature === layer.layername);
        if (filteredLayer) {
          res.data.features.forEach((feature: any) => {
            feature.layerConfig = filteredLayer;
          });
        }

        return res.data;
      } catch (error) {
        console.error("Une erreur s'est produite lors de la récupération des données :", error);
        throw error;
      }
    }

  getLabel(key:any) {
    if (this.layerOfFeature && this.layerOfFeature.layerProperties) {
      const searchAttribute = this.layerOfFeature.layerProperties.filter((data) => data.name === key)[0];
      if (searchAttribute !== undefined && searchAttribute.label !== undefined && searchAttribute.label !== '') {
        return searchAttribute.label;
      }
    }

    return key;
  }

  isUrl(value: any) {
    return typeof value === 'string' && (value.startsWith('http://') || value.startsWith('https://'));
  }

  changePage(page : string): void {
    this.currentPage = page;
  }

}
