/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable class-methods-use-this */
/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable no-eval */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import { Feature } from 'ol';
import GeoJSONFormat from 'ol/format/GeoJSON';
import BaseLayer from 'ol/layer/Base';
import VectorLayer from 'ol/layer/Vector';
import sanitizeHtml from 'sanitize-html';
import AbstractLayerTreeElement from './AbstractLayerTreeElement';
import AuthenticationInfos from './AuthenticationInfos';
import DatavizConfig from './DatavizConfig';
import GeometryType from './GeometryType';
import Layer3dConfig from './Layer3dConfig';
import LayerAttribute from './LayerAttribute';
import LayerStyle from './LayerStyle';
import LayerStyleComplex from './LayerStyleComplex';
import ModelConfig from './cesium/ModelConfig';
import StyleRule from './style/StyleRule';

export default class LayerConfig extends AbstractLayerTreeElement {
  layername!: string;

  title!: string;

  tiled = true;

  applicableCoupe = '';

  description = '';

  outputFormat!: string;

  protocol!:string;

  mbStyleUrl!:string;

  getFeatureInfoFormat = 'application/json';

  Attribution: any = {};

  metadataUrl = '';

  time!: string;

  geometryType!: string;

  filter!: string;

  tableFilter!: string;

  geometricFilter!: string;

  tableGeometricFilter!: string;

  dataviz= new DatavizConfig();

  selectionColor= '';
  // Fiche Infos
  ficheInfos = { content: '', active: false, edited: false,width:30 };

  // Infobulle
  popupInfos = { content: '', active: false, edited: false };

  featureInfoContentRenderer!:(feature:Feature<any>, target:HTMLElement) => void;

  authenticationInfos= new AuthenticationInfos();

  modelConfig!:ModelConfig;

  jsonData!: string;

  type!: string;

  projection!: string;

  dataProjection!: string; // projection des données

  styles: any;

  useSldBody = false;

  opacity?: number = 1;

  layerProperties: LayerAttribute[]=Array<LayerAttribute>();

  visible = false;

  removable = true;

  editable = false;

  editing = false;

  queryable = true;

  targetNamespace!: string;

  targetPrefix!: string;

  isLegendDisplayed = true;

  legendImageUrl = '';

  public baseLayer = false;

  layerIndex!: number;

  maxScaleDenominator!: number;

  minScaleDenominator!: number;

  boundingBoxEPSG4326!: number[];

  url!: string;

  ol_Layer?: BaseLayer;

  cesium_Layer?:any;

  style = new LayerStyle();

  complexStyle = new LayerStyleComplex();

  maxFeatures = 5000;

  selectedStyle: any;

  error = {show:false,
           type:-1,
          message:''};

  idProperty='';

  useProxy = false;

  ignoreUrlInCapabiltiesResponse = false;

  isWFsAble = false; // indique si une couche WMS est aussi disponible en WFS

  isWmtsAble = false; // indique si une couche WMS est aussi disponible en WMTS

  layer3dConfig = new Layer3dConfig();

  constructor(input?: any) {
    super(input);
    if (input) {
      this.loadFromJson(input);
    }
  }

  loadFromJson(input:any) {
    Object.assign(this, input);
    this.style = new LayerStyle(input.style);
    if (!this.Attribution) {
      this.Attribution = {};
    }
    this.fixIgnUrls();

    if (input.dataviz) {
      this.dataviz = new DatavizConfig(input.dataviz);
    }
    if (input.layerProperties) {
      this.layerProperties = input.layerProperties.map((x:any) => new LayerAttribute(x));
    }
    if (input.complexStyle && input.complexStyle.styleRules) {
      this.complexStyle = new LayerStyleComplex(this.complexStyle, this.style);
    } else {
      this.complexStyle = new LayerStyleComplex();
    }
    if (input.authenticationInfos) {
      this.authenticationInfos = new AuthenticationInfos(input.authenticationInfos);
      this.loadAuthenticationInfosFromLocalStorage();
    }
    if (input.modelConfig) {
      this.modelConfig = new ModelConfig(input.modelConfig);
    }
    if (input.config3d) {
      this.layer3dConfig = new Layer3dConfig(input.config3d);
    }
    // opacity était entre 0 et 100 et maintenant entre 0 et 1,
    // ceci pour traiter les anciennes cartes
    if (this.opacity && this.opacity > 1) {
      this.opacity /= 100;
    }
  }

  fixIgnUrls():void {
    if (this.url && this.url.indexOf('wxs.ign.fr') > 0 ) { // && this.url.indexOf('/tms/') < 0
      this.url = this.url.replace(new RegExp('wxs.ign.fr/(.*)/geoportail/', 'ig'), 'data.geopf.fr/');
      this.url = this.url.replace(new RegExp('/v/', 'ig'), '/wms-v/');
      this.url = this.url.replace(new RegExp('/r/', 'ig'), '/wms-r/');
    }
    if (this.mbStyleUrl && this.mbStyleUrl.indexOf('wxs.ign.fr') > 0 ) { 
      this.mbStyleUrl = this.mbStyleUrl.replace(new RegExp('wxs.ign.fr/static', 'ig'), 'data.geopf.fr/annexes/ressources');
      
    }
  }

  checkSourceAuthentification():void {
    this.loadAuthenticationInfosFromLocalStorage();

    if (this.authenticationInfos !== undefined && this.authenticationInfos.isRequired()) {
      if (this.authenticationInfos.isFilled()) {
        console.log('ok auth infos provided');
      } else {
        this.addError("Cette couche nécessite des informations d'authentication");
      }
    }
  }

  loadAuthenticationInfosFromLocalStorage():void {
    const localInfos = localStorage.getItem('localStorageAuthDB');

    if (localInfos !== undefined && localInfos !== null) {
      const localStorageAuthDB:any = JSON.parse(localInfos);
      const authInfos = localStorageAuthDB[this.url];
      if (authInfos) {
        this.authenticationInfos = new AuthenticationInfos(authInfos);
      }
    }
  }

  hasAuthenticationInfos():boolean {
    return this.authenticationInfos !== undefined && this.authenticationInfos.isFilled();
  }

  isGroup(): boolean {
    return false;
  }

  getGeomAttributeName(): string {
    if (!this.layerProperties) {
      return 'the_geom';
    }
    const geomAttr = this.layerProperties.find((x: { type: string | string[]; }) => x.type && x.type.indexOf('gml:') >= 0);
    if (!geomAttr) {
      return 'the_geom';
    }
    return geomAttr.name;
  }

  getIdPropery(): string {
    const retour = '';
    if (this.idProperty !== '') {
      return this.idProperty;
    }
    if (this.layerProperties && this.layerProperties.length > 0) {
      console.log(this.layerProperties);
      const props = this.layerProperties.filter((x: { name: string; }) => x.name.toLowerCase().indexOf('id') >= 0);
      console.log(props);
      if (props.length > 0) return props[0].name;
    }
    return retour;
  }

  setVisible(visible: boolean): void {
    this.visible = visible;
    if (this.ol_Layer) {
      this.ol_Layer.setVisible(this.isLayerVisible());
    }
    if (this.cesium_Layer) {
      this.cesium_Layer.show = this.isLayerVisible();
    }
  }

  getOpacity() {
    if (this.ol_Layer) {
      return this.ol_Layer.getOpacity();
    }
    if (this.cesium_Layer) {
      return this.cesium_Layer.alpha;
    }
    return undefined;
  }

  setOpacity(opacity: number): void {
    if (this.ol_Layer) {
      this.ol_Layer.setOpacity(opacity);
    }
    if (this.cesium_Layer) {
      this.cesium_Layer.alpha = opacity;
    }
  }

  getTimeValue(): string {
    // const date = new Date();
    const date = new Date( new Date().setHours(0, 0, 0, 0))
    // console.log(date);
    
    const cuurentDate = date
    const today = cuurentDate.toJSON()

    const dateCopy = new Date(date)
    dateCopy.setDate(dateCopy.getDate()+1)
    const tomorrow = dateCopy.toJSON()
    
    let time = ''


    if (this.time){
      time = this.time
    }else{
      time = `${today}/${tomorrow}`
    }
    return time
  }

  guessGeometryType(): string {
    let retour = GeometryType.UNDEFINED;
    if (this.ol_Layer && this.ol_Layer instanceof VectorLayer) {
      const layer: VectorLayer<any> = <VectorLayer<any>> this.ol_Layer;
      const features = layer.getSource().getFeatures();
      if (features && features.length > 0) {
        let type = '';
        const geom = features[0].getGeometry();
        if (geom) {
          type = geom.getType();
        }
        if (type === 'MultiLineString' || type === 'LineString') {
          retour = GeometryType.LINE;
        } else if (type === 'MultiPolygon' || type === 'Polygon') {
          retour = GeometryType.POLYGON;
        } else if (type === 'MultiPoint' || type === 'Point') {
          retour = GeometryType.POINT;
        }
      }
    }
    return retour;
  }

  getFilterValue(): string {
    // eslint-disable-next-line no-useless-escape
    const filterChildByAttribute = new RegExp(/\{([^\}]*)\}/g);
    let filter = '';
    if (this.filter && this.filter.indexOf('{') >= 0) {
      filter = this.filter.replace(filterChildByAttribute, (a, b, c) => eval(b));
    } else if (this.filter) {
      filter = this.filter;
    }
    if (this.geometricFilter && this.geometricFilter.length > 0) {
      if (filter.length > 0) {
        filter = `(${filter} AND ${this.geometricFilter})`;
      } else {
        filter = this.geometricFilter;
      }
    }
    // add Filter from user Selection In LayerPanel if needed
    if (this.complexStyle && this.complexStyle.displayInLayerPanel) {
      this.complexStyle.styleRules.forEach((rule:StyleRule) => {
        if (!rule.active) {
          if (filter.length > 0) {
            filter = `(${filter} AND (NOT (${rule.value})))`;
          } else {
            filter = `NOT (${rule.value})`;
          }
        }
      });
    }

    return filter;
  }

  /**
  * Filtre pour la table attributaire
  */
  getTableFilterValue(): string {
    let filter = this.getFilterValue();
    if (this.tableFilter && this.tableFilter.length > 0) {
      if (filter && filter.length > 0) {
        filter = `(${filter} AND ${this.tableFilter})`;
      } else {
        filter = this.tableFilter;
      }
    }
    if (this.tableGeometricFilter && this.tableGeometricFilter.length > 0) {
      if (filter && filter.length > 0) {
        filter = `(${filter} AND ${this.tableGeometricFilter})`;
      } else {
        filter = this.tableGeometricFilter;
      }
    }
    return filter;
  }

  hasEditionCapabilities():boolean {
    return this.type === 'WFS' || this.type === 'Vector';
  }

  hasAnalyseDisplayedInLayerPanel():boolean {
    return this.complexStyle && this.complexStyle.displayInLayerPanel;
  }

  hasRules():boolean {
    return this.complexStyle && this.complexStyle.styleRules.length > 0;
  }

  getStyleFunction(): any {
    let retour:any = (feature:any, resolution:any) => this.style.getStyle(feature, resolution);

    if (this.complexStyle
      && this.complexStyle.styleRules
      && this.complexStyle.styleRules.length > 0) {
      retour = (feature:any, resolution:any) => {
        const s = this.complexStyle.getStyle(feature, resolution);
        if (s) {
          return s;
        }

        return null;
      };
    }
    return retour;
  }

  geometryTypeNotDefined():boolean {
    return this.geometryType === undefined
    || this.geometryType === GeometryType.UNDEFINED;
  }

  getCapabilitiesUrl(): string {
    var searchMask = 'getCapabilities';
    var regEx = new RegExp(searchMask, 'ig');
    if (regEx.test(this.url)) {
      return this.url;
    }
    if(this.url.indexOf('?') >= 0) {
      return `${this.url}&request=getCapabilities`;
    }
    else{
      return `${this.url}?request=getCapabilities`;
    }
   
  }
  getBuffer():number{
    let buffer=5;
      if(this.geometryType!==undefined && this.geometryType==GeometryType.POLYGON){
        buffer = 0;
      }
    return buffer;
  }
  getFeatureInfoUrl():string {
    if (this.isWMS() && this.url.indexOf('?') >= 0) {
      const i = this.url.indexOf('?');
      let buffer=this.getBuffer();
      const url = `${this.url.slice(0, i + 1)}service=wms&version=1.3.0&request=GetFeatureInfo&QUERY_LAYERS=${this.layername}&layers=${this.layername}&EXCEPTIONS=XML&INFO_FORMAT=application/json&BUFFER=${buffer}&FEATURE_COUNT=10&FI_LINE_TOLERANCE=10&FI_POINT_TOLERANCE=25&FI_POLYGON_TOLERANCE=5`;
      return url;
    }
    return '';
  }

  getDownloadUrl(format: any): string|undefined {
    let capabilitiesUrl = this.getCapabilitiesUrl();
    let regEx = new RegExp('getCapabilities', 'ig');
    if (format.type === 'WMS') {
      const urlservice = capabilitiesUrl.replace(regEx, 'GetMap');
      return `${urlservice}&version=1.3.0&format=${format.value}&layers=${this.layername}&WIDTH=1320&HEIGHT=700&CRS=EPSG:4326&BBOX=${this.boundingBoxEPSG4326[1]},${this.boundingBoxEPSG4326[0]},${this.boundingBoxEPSG4326[3]},${this.boundingBoxEPSG4326[2]}`;
    }
    if (this.isWMS()) { // si c'est une couche WMS, on recupère l'url du flux WFS associé
      capabilitiesUrl = this.wmsUrlToWfs(capabilitiesUrl);
    }
    const urlservice = capabilitiesUrl.replace(regEx, 'GetFeature');
    regEx = new RegExp('service(=|%3D)wms', 'ig');
    return `${urlservice.replace(regEx, 'service=WFS')}&outputFormat=${format.value}&TypeNames=${this.layername}&TypeName=${this.layername}`;
  }

  getDescribeFeatureUrl(): string {
    if (this.isWMS() && this.url.indexOf('?') >= 0) {
      const wfsUrl = this.wmsUrlToWfs(this.url);
      const i = wfsUrl.indexOf('?');
      const url = `${wfsUrl.slice(0, i + 1)}service=wfs&request=DescribeFeatureType&TypeNames=${this.layername}&TypeName=${this.layername}`;
      return url;
    }
    if (this.isWMS() && this.url.indexOf('ows') >= 0) {
      const url = `${this.url}?service=wfs&request=DescribeFeatureType&TypeNames=${this.layername}&TypeName=${this.layername}`;
      return url;
    }
    if (this.isWFS()) {
      const regEx = new RegExp('request(=|%3D)getCapabilities', 'ig');
      const replaceMask = 'request=DescribeFeatureType';
      const url = `${this.url.replace(regEx, replaceMask)}&TypeNames=${this.layername}&TypeName=${this.layername}`;// &outputFormat=application/json
      return url;
    }
    if (this.isWMTS() || this.isVectorTile()) {
      const serviceRegex = new RegExp('gwc/service/wmts', 'ig');
      const serviceRegEx2 = new RegExp('service(=|%3D)wmts', 'ig');
      let url = this.url.replace(serviceRegex, 'wfs').replace(serviceRegEx2, 'service=wfs');
      const regEx = new RegExp('request(=|%3D)getCapabilities', 'ig');
      const replaceMask = 'request=DescribeFeatureType';
      url = `${url.replace(regEx, replaceMask)}&TypeNames=${this.layername}&TypeName=${this.layername}&outputFormat=application/json`;
      return url;
    }
    return '';
  }

  getFeatureWFSUrl():string {
    const searchMask = 'getCapabilities';
    const regEx = new RegExp(searchMask, 'ig');
    const replaceMask = 'GetFeature';
    return this.getCapabilitiesUrl().replace(regEx, replaceMask);
  }

  getWmtsCapabilitiesUrl():string {
    let url = this.getCapabilitiesUrl();

    // Pour Geoserver, on peut chercher a trouver l'url WMTS à partir du WMS
    // Si c'est une couche WMS, on transforme l'url pour pointer vers
    if (this.isWMS()) {
      let searchMask = '/(ows|wms)';
      let regEx = new RegExp(searchMask, 'ig');
      let replaceMask = '/gwc/service/wmts';
      url = url.replace(regEx, replaceMask);
      searchMask = 'service(=|%3D)(wms|ows)';
      regEx = new RegExp(searchMask, 'ig');
      replaceMask = 'service=WMTS';
      url = url.replace(regEx, replaceMask);
      searchMask = 'version(=|%3D)(1.3.0|1.1.1|1.1.0)';
      regEx = new RegExp(searchMask, 'ig');
      replaceMask = '';
      url = url.replace(regEx, replaceMask);
    }
    const searchMask = 'service(=|%3D)wmts';
    const regEx = new RegExp(searchMask, 'ig');
    if (regEx.test(url)) {
      return url;
    }

    url += '&service=WMTS';
    return url;
  }

  wmsUrlToWfs(url:string):string {
    const serviceRegex = new RegExp('service(=|%3D)wms', 'ig');
    const versionRegex = new RegExp('version(=|%3D)1.3.0', 'ig');
    const wmsRegex = new RegExp('(wms|ows)', 'ig');
    return url.replace(serviceRegex, 'service=wfs').replace(versionRegex, 'version=2.0.0').replace(wmsRegex, 'wfs');
  }

  wfsUrlToWms(url:string):string {
    const serviceRegex = new RegExp('service(=|%3D)wfs', 'ig');
    const versionRegex = new RegExp('version(=|%3D)2.0.0', 'ig');
    const wfsRegex = new RegExp('wfs', 'ig');
    return url.replace(serviceRegex, 'service=wms').replace(versionRegex, 'version=1.3.0').replace(wfsRegex, 'wms');
  }

  hasMapBoxStyle():boolean {
    return this.protocol === 'TMS'
      && this.type === 'VectorTile'
      && this.mbStyleUrl !== undefined && this.mbStyleUrl.length > 0;
  }

  isStylable():boolean {
    return this.type !== 'WMTS' && this.type !== '3DTiles_Photomaillage' && this.type !== '3DTilesPointCloud' && this.type !== '3DTiles'
      && !this.hasMapBoxStyle();
  }

  isCutable():boolean {
    return this.type === '3DTiles_Photomaillage' || this.type === '3DTiles' || this.is3DModel();
  }

  is3Dtiles() {
    return this.type === '3DTiles_Photomaillage' || this.type === '3DTiles';
  }

  is3DModel() {
    return this.type === '3dmodel';
  }

  getWFSUrl(extent:any, projection:any): string {
    const urlservice = this.getFeatureWFSUrl();
    let outputformat = 'application/json';
    if (this.outputFormat) {
      outputformat = encodeURIComponent(this.outputFormat);
    }

    let proj = 'EPSG:3857';
    let url = `${urlservice
    }&maxfeatures=${this.maxFeatures
    }&count=${this.maxFeatures
    }&typenames=${this.layername}`
      + `&typename=${this.layername}&`
      + `outputFormat=${outputformat}&srsname=${proj}`;

    if (this.filter || this.geometricFilter || this.tableFilter || this.tableGeometricFilter) {
      // const cql_filter = `BBOX(${this.getGeomAttributeName()},${extent.join(',')})
      // AND ${this.getFilterValue()}`;
      /*
      const cql_filter = `${this.getFilterValue()}`;
      const filter = new Filter();
      filter.fromCQL(cql_filter);
      const xmlFilter = filter.toSld();
      url += `&Filter=${xmlFilter}`;
      */
      url += `&CQL_FILTER=BBOX(${this.getGeomAttributeName()},${extent.join(',')},'${proj}') AND ${encodeURIComponent(this.getTableFilterValue())}`;
    } else {
      url += `&bbox=${extent.join(',')},${proj}`;
    }
    return url;
  }

  updateStyle() :void{
    if (this.type === 'WMS' && !this.hasMapBoxStyle()) {
      if (this.useSldBody) {
        const sld = this.styletoSld();
        this.getLayerSource().updateParams({ SLD_BODY: sld });
      } else {
        this.getLayerSource().updateParams({ SLD_BODY: undefined });
        if(this.selectedStyle && this.selectedStyle.length>0){
          this.getLayerSource().updateParams({ STYLES: this.selectedStyle });
        }
        if (this.getFilterValue().length > 0) {
          this.getLayerSource().updateParams({ CQL_FILTER: this.getFilterValue() });
        } else {
          this.getLayerSource().updateParams({ CQL_FILTER: undefined });
        }
      }
    } else if (!this.hasMapBoxStyle()) {
      this.style.clearCache();
      /* TODO
      if (layer.dynamicStyle) {
        layer.dynamicStyle.clearStylesCache();
      }
      */
      if (this.getOlLayer() && this.getOlLayer().setStyle) {
        this.getOlLayer().setStyle(this.getStyleFunction());
      }
    }
  }

  getLayerName(){
    let layerName = this.layername;
    if (layerName.indexOf(':') > 0) {
      const workspace = layerName.split(':')[0];
      if (this.url.indexOf(`/${workspace}/`) > 0) {
        layerName = layerName.split(':')[1];
      }
    }
    return layerName;
  }

  setTimeValue(time:any): void{
    this.time = time
  }
  
  styletoSld(): string {
    let rules = `<Rule>${this.style.toSld(this)}</Rule>`;
    if (this.complexStyle.isDefined()) {
      rules = this.complexStyle.toSld(this);
    }

    // REMOVE WORKSPACE FROM LAYERNAME IF ALREADY IN URL
    let layerName=this.getLayerName();
    // ---------------------------------------------------
    const sld = `<?xml version="1.0" encoding="ISO-8859-1"?>
        <StyledLayerDescriptor version="1.0.0"
            xsi:schemaLocation="http://www.opengis.net/sld StyledLayerDescriptor.xsd"
            xmlns="http://www.opengis.net/sld"
            xmlns:ogc="http://www.opengis.net/ogc"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
          <NamedLayer>
            <Name>${layerName}</Name>
            <UserStyle>
                <Title>${layerName} style</Title>
                <Abstract></Abstract>
                <FeatureTypeStyle>
                  ${rules}
                </FeatureTypeStyle>
            </UserStyle>
            </NamedLayer>
        </StyledLayerDescriptor>`;
    return sld;
  }

  toJsonDuplication():any {
    let retour=this.toJson();
    delete retour.uid;
    retour.title+=' (Copie)'
    return retour;
  }
  toJson():any {
    var newLayer:any = {
      uid: this.uid,
      layername: this.layername,
      title: this.title,
      type: this.type,
      visible: this.isVisible(),
      useSldBody: this.useSldBody,
      dataviz: this.dataviz.toJson(),
      removable: this.removable,
      time: this.time,
      editable: this.editable,
      selectionColor: this.selectionColor,
      queryable: this.queryable,
      protocol: this.protocol,
      mbStyleUrl: this.mbStyleUrl,
      idProperty: this.idProperty,
      targetNamespace: this.targetNamespace,
      targetPrefix: this.targetPrefix,
      dataProjection: this.dataProjection,
      maxFeatures: this.maxFeatures,
      layerIndex: this.layerIndex,
      maxScaleDenominator: this.maxScaleDenominator,
      minScaleDenominator: this.minScaleDenominator,
      boundingBoxEPSG4326: this.boundingBoxEPSG4326,
      filter: this.filter,
      geometricFilter: this.geometricFilter,
      outputFormat: this.outputFormat,
      getFeatureInfoFormat: this.getFeatureInfoFormat,
      url: this.url,
      tiled: this.tiled,
      baseLayer: this.baseLayer,
      opacity: this.ol_Layer ? this.ol_Layer.getOpacity() : 1,
      isLegendDisplayed: this.isLegendDisplayed,
      legendImageUrl: this.legendImageUrl,
      description: this.description,
      popupInfos: { ...this.popupInfos },
      ficheInfos: { ...this.ficheInfos },
      style: this.style.toJson(),
      selectedStyle:this.selectedStyle,
      styles:this.styles,
      complexStyle: {},
      Attribution: this.Attribution,
      metadataUrl: this.metadataUrl,
      geometryType: this.geometryType,
      ignoreUrlInCapabiltiesResponse: this.ignoreUrlInCapabiltiesResponse,
      layerProperties: Array<any>(),
      authenticationInfos: {},
      jsonData: '',
      config3d: this.layer3dConfig.toJson(),
    };

    if (this.modelConfig) {
      newLayer.modelConfig = this.modelConfig.toJson();
    }

    if (this.complexStyle) {
      newLayer.complexStyle = this.complexStyle.toJson();
    }
    if (this.layerProperties) {
      newLayer.layerProperties = this.layerProperties.map((x:LayerAttribute) => x.toJson());
    }
    // on ne sauve pas les infos d'authentification dans la config de la carte
    // elles sont stockées dans le localstorage si l'utilisateur le demande
    if (this.authenticationInfos && this.authenticationInfos.isRequired()) {
      newLayer.authenticationInfos = { type: this.authenticationInfos.type };
    }
    if (this.type === 'Vector' && this.url === undefined && this.ol_Layer && this.ol_Layer instanceof VectorLayer) {
      const feats = this.getLayerSource().getFeatures();
      const featstring = (new GeoJSONFormat()).writeFeatures(feats);
      const sizeInko = Number(featstring.length / 1024);
      if (sizeInko > 500) {
        this.addError(`Trop de données dans cette couche pour la sauvegarde( ${sizeInko} ko), vous devez supprimer des objets`);
        throw new Error('TooMuchDatas');
      } else {
        newLayer.jsonData = featstring;
        newLayer.dataProjection = 'EPSG:3857';
      }
    }

    return newLayer;
  }

  getLegendImageUrl(): string {
    if (this.legendImageUrl) {
      return this.legendImageUrl;
    }
    let url;
    if (this.ol_Layer && this.getLayerSource().urls) {
      url = this.getLayerSource().urls[0];
    } else if (this.ol_Layer) {
      url = this.getLayerSource().getUrl();
    }
    url += '&REQUEST=GetLegendGraphic&SLD_VERSION=1.1.0&FORMAT=image%2Fpng&WIDTH=30&HEIGHT=30&LAYER=';

    url += this.layername;
    
    if (this.useSldBody) {
      const sld = this.styletoSld();
      url += `&SLD_BODY=${encodeURIComponent(sld)}`;
    }
    else if(this.selectedStyle && this.selectedStyle.length>0){
      url += `&STYLE=${this.selectedStyle}`;
    }

    return url;
  }

  getAttribution(): string | undefined {
    let attributions;
    if (this.Attribution && this.Attribution.OnlineResource) {
      const url = encodeURIComponent(this.Attribution.OnlineResource);
      attributions = `<a href="${url}" target="_blank">${sanitizeHtml(this.Attribution.Title)}</a> `;
    } else if (this.Attribution) {
      attributions = sanitizeHtml(this.Attribution.Title);
    }
    return attributions;
  }

  setOL_Layer(layer: BaseLayer): void {
    this.ol_Layer = layer;
    (<any>layer).customConfig = this;
  }

  setCesium_Layer(layer: any): void {
    this.cesium_Layer = layer;
    (<any>layer).customConfig = this;
  }

  getCesiumLayer(): any {
    return this.cesium_Layer;
  }

  getOlLayer(): any {
    return this.ol_Layer;
  }

  getLayerSource(): any {
    if (this.ol_Layer) {
      return this.getOlLayer().getSource();
    }
    if (this.cesium_Layer) {
      return this.getCesiumLayer();
    }
    return undefined;
  }

  isWMS():boolean {
    return this.type === 'WMS';
  }

  inRange(currentScale: number):boolean {
    if (!currentScale) {
      return true;
    }
    const maxScaleOk = !this.maxScaleDenominator
    || (this.maxScaleDenominator > currentScale);
    const minScaleOK = !this.minScaleDenominator
    || (this.minScaleDenominator < currentScale);
    return maxScaleOk && minScaleOK;
  }

  hasTableCapacity():boolean {
    return this.hasWFSCapacity() || this.isVector() || this.isVectorTile();
  }

  isVectorRenderLayer():boolean {
    return this.isWFS() || this.isVector() || this.isVectorTile();
  }

  isVector():boolean {
    return this.type === 'Vector';
  }

  isVectorTile():boolean {
    return this.type === 'VectorTile';
  }

  isWFS():boolean {
    return this.type === 'WFS';
  }

  hasWFSCapacity():boolean {
    return this.type === 'WFS' || this.isWFsAble;
  }

  hasWMTSCapacity():boolean {
    return this.isWmtsAble;
  }

  hasWMSCapacity():boolean {
    return this.url!=undefined && this.url.indexOf('gwc/service/wmts')>0;
  }

  isWMTS():boolean {
    return this.type === 'WMTS';
  }

  getScaleZ(): number {
    return this.layer3dConfig.getScaleZ();
  }

  setScaleZ(scale: number): void {
    this.layer3dConfig.setScaleZ(scale);
  }

  getOffsetZ() :number {
    return this.layer3dConfig.getOffsetZ();
  }

  setOffsetZ(offset: number): void {
    this.layer3dConfig.setOffsetZ(offset);
  }

  getOffsetZMin():number {
    return this.layer3dConfig.getOffsetZMin();
  }

  setOffsetZMin(offset: number): void {
    this.layer3dConfig.setOffsetZMin(offset);
  }

  getOffsetZMax():number {
    return this.layer3dConfig.getOffsetZMax();
  }

  setOffsetZMax(offset: number): void {
    this.layer3dConfig.setOffsetZMax(offset);
  }

  getScaleZMin():number {
    return this.layer3dConfig.getScaleZMin();
  }

  setScaleZMin(scale: number): void {
    this.layer3dConfig.setScaleZMin(scale);
  }

  getScaleZMax():number {
    return this.layer3dConfig.getScaleZMax();
  }

  setScaleZMax(scale: number): void {
    this.layer3dConfig.setScaleZMax(scale);
  }

  getStepOffset(): number {
    return this.layer3dConfig.getStepOffset();
  }

  getStepScale(): number {
    return this.layer3dConfig.getStepScale();
  }

  isTilesetScalable(cesiumMapService:any): boolean {
    if (this.cesium_Layer) {
      return cesiumMapService.isTilesetScalable(this);
    }
    return false;
  }

  // fct pour forcer màj opacité dans le cas de la 3D
  forceUpdateOpacity(cesiumMapService:any) {
    if (cesiumMapService && this.cesium_Layer) {
      cesiumMapService.updateLayer(this);
    }
  }

  addError(m: string,code:number=-1) {
    this.error.show=true;
    this.error.message=m;
    this.error.type=code;
  }
  resetErrors() {
    this.error.show=false;
    this.error.message='';
    this.error.type=-1;
  }
}
