/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import AbstractLayerTreeElement from '@/models/map-context/AbstractLayerTreeElement';
import AuthenticationInfos from '@/models/map-context/AuthenticationInfos';
import { pluginServiceInstance, PluginService } from '@/services/PluginService';
import MapContext from '@/models/map-context/MapContext';
import Plugin from '@/models/plugin/Plugin';
import axios, { AxiosResponse } from 'axios';
import Application from '@/models/map-context/Application';
import { BIconFileEarmarkPerson } from 'bootstrap-vue';
import LayerConfig from '@/models/map-context/LayerConfig';
import ColorPalette, { IColorPalette } from '@/models/ColorPalette';
import { Observable } from 'rxjs';
import AdministrativeArea from '@/models/shared/AdministrativeArea';
import DownloadProjection from '@/models/shared/DownloadProjection';
import User from '@/models/user/User';
import { EventBus, EVENTS } from './EventBus';

class ContextService {
  currentContext: MapContext |null= null;

  is3d=false;
  
  currentApplication: Application |null= null;

  localContext: MapContext |null= null;

  localApplication: Application |null= null;

  currentActionTarget!: any;

  currentAction='';

  termsValidationRequired = false;

  isEditionActive = false;

  contentEditionIsAllowed = true;

  configLoaded = false;

  showHeader = true;

  customHeaderAndFooter = false; // intégration d'un header et d'un footer personnalisé

  simpleHeader = false; // header simplifié pour les applis qui ont déjà leur propre header

  showFooter = true;

  tablelayer:LayerConfig|null = null; // couche actuelle affichée dans le composant FeatureListPanel

  loading = false; // gère l'affichage de la fenetre de chargement

  user:any = null;

  mapDefault: string="";

  mapNb!: number;
  // user:any = { email: 'dds', is_superuser: true, usergroup_roles: [{ usergroup: { id: 1 } }] };//= null;

  private draggingLayer: AbstractLayerTreeElement | null=null;

  configuration!:any;

  svgPictos!:any;

  colorPalettes:ColorPalette[]=[];

  private localStorageAuthDB:any = {};

  administrativeAreas =[];

  downloadProjection =[];


  clipboard:any=null;

  constructor() {
    const localInfos = localStorage.getItem('localStorageAuthDB');

    if (localInfos !== undefined && localInfos !== null) {
      this.localStorageAuthDB = JSON.parse(localInfos);
    }

    const clipboard = localStorage.getItem('maps_clipboard');

    if (clipboard !== undefined && clipboard !== null) {
      this.clipboard = JSON.parse(clipboard);
    }


    this.loadConfig().then((result) => {
      this.configLoaded = true;
      this.configuration = result.data;
      this.onConfigLoaded(this.configuration);
      this.loadUserStatus();
    });

    this.loadSvgPictos().then((result) => {
      console.log('pictos loaded');
      this.svgPictos = result.data;
    });
    this.loadPaletteList().then((result) => {
      console.log('palettes loaded');
      this.colorPalettes = result.data.palettes.map((x:IColorPalette) => new ColorPalette(x));
    });
  }

  addToClipBoad(obj:any){
    this.clipboard=obj;
    //put in local storage
    localStorage.setItem('maps_clipboard', JSON.stringify(obj));
  }

  clearClipBoad(){
    this.clipboard=null;
    localStorage.removeItem('maps_clipboard');
  }

  onConfigLoaded(config:any) {
    if (this.configuration.administrativeAreas && this.configuration.administrativeAreas.length > 0) {
      this.administrativeAreas = this.configuration.administrativeAreas.map((x:any) => new AdministrativeArea(x));
    }
    if (this.configuration.downloadProjections && this.configuration.downloadProjections.length > 0) {
      this.downloadProjection = this.configuration.downloadProjections.map((x:any) => new DownloadProjection(x));
    } else {
      this.configuration.downloadProjections = [{
        label: 'EPSG:2154',
        codeEpsg: 'EPSG:2154',
      },
      {
        label: 'EPSG:4326',
        codeEpsg: 'EPSG:4326',
      },
      {
        label: 'EPSG:3857',
        codeEpsg: 'EPSG:3857',
      }];
      this.downloadProjection = this.configuration.downloadProjections.map((x:any) => new DownloadProjection(x));
    }

    if (config.header && config.header.show !== undefined) {
      this.showHeader = config.header.show;
    }
    if (config.header && config.header.simple !== undefined) {
      this.simpleHeader = config.header.simple;
    }
    if (config.footer && config.footer.show !== undefined) {
      this.showFooter = config.footer.show;
    }
    if (config.customHeaderAndFooter && config.customHeaderAndFooter.show === true) {
      this.customHeaderAndFooter = true;
      const headElement = document.getElementsByTagName('head')[0];
      let s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = config.customHeaderAndFooter.header_url;
      headElement.append(s);
      s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = config.customHeaderAndFooter.footer_url;
      headElement.append(s);
    }
  }

  createMap():void {
    // delete old map
    this.currentContext = null;
    this.getApp('empty_template.json').then((reponse:any) => {
      console.log(reponse.data);
      const retour = reponse.data;
      let public_access = false;
      let is_default = false;

      let highlight = false;
      if (this.configuration.new_map_defaults && this.configuration.new_map_defaults.public !== undefined) {
        public_access = this.configuration.new_map_defaults.public;
      }
      if (this.configuration.new_map_defaults && this.configuration.new_map_defaults.highlight !== undefined) {
        highlight = this.configuration.new_map_defaults.highlight;
      }
      const newApp = new Application({
        display_name: 'Mon appli',
        public_access,
        is_default,
        highlight,
      });
      const currentAppConfig = new MapContext(retour);
      this.loadContext(newApp, currentAppConfig);
      EventBus.$emit(EVENTS.OPEN_LEFT_SIDE_MENU, 'SettingsPanel');
    });
  }

  getDraggingLayer(): AbstractLayerTreeElement | null{
    return this.draggingLayer;
  }

  setDraggingLayer(layer:AbstractLayerTreeElement | null) :void {
    console.log('set dragging layer', layer);
    this.draggingLayer = layer;
  }

  loadContext(application:Application, appConfig: MapContext): void {
    this.currentApplication = application;
    this.currentContext = appConfig;
  }

  startEdition() :void{
    this.isEditionActive = true;
  }

  cancelEdition() :void{
    this.isEditionActive = false;
  }

  getCurrentApplication(): Application {
    if (this.currentApplication == null) {
      throw new Error('Application not loaded!');
    }
    return this.currentApplication;
  }

  getCurrentContext(): MapContext {
    if (this.currentContext == null) {
      throw new Error('Context not loaded!');
    }
    return this.currentContext;
  }

  getCurrentApplicationMapAccess() {
    if (this.currentApplication == null) {
      return [];
    }

    if (this.currentApplication.map_access.length === 0) {
      return [];
    }

    return this.currentApplication.map_access;
  }

  hasUserEditAccess():boolean {
    const lstMapAccess = this.getCurrentApplicationMapAccess();
    if (lstMapAccess.length === 0) {
      return false;
    }
    const currentUser = this.user;
    const match = lstMapAccess.filter((x:any) => x.access_type === 'write'
      && currentUser.groups && currentUser.groups.find((grp:any)=> grp.id === x.groupId));
    if (match.length > 0) {
      return true;
    }
    return false;
  }
  
  setBaseLayers(baseLayers :any): void {
    if (this.currentContext?.baseLayers == null) {
      throw new Error('Context not loaded!');
    }
    this.currentContext.baseLayers = baseLayers;
  }

  isContextLoaded(): boolean {
    return this.currentContext !== null;
  }

  setCurrentAction(action: string, target: any): void {
    this.currentActionTarget = target;
    this.currentAction = action;
  }

  getCsrfToken():string {
    const csrfToken:any = ((name) => {
      const re = new RegExp(`${name}=([^;]+)`);
      const value = re.exec(document.cookie);
      return (value != null) ? unescape(value[1]) : null;
    })('csrftoken');
    return csrfToken;
  }

  removeApp(map: Application):Promise<any> {
    return axios.delete(`${this.getMapsApiUrl()}/${map.id}/`, { headers: { 'X-CSRFToken': this.getCsrfToken() } }).then((result) => {
      console.log(result);
    });
  }

  deleteCurrentMap():Promise<any> {
    if (this.currentApplication !== null) {
      return this.removeApp(this.currentApplication);
    }
    throw new Error('Aucune application');
  }

  getJsonContext():any {
    if (this.currentContext !== null) {
      const mapContext = this.currentContext.toJson();
      pluginServiceInstance.plugins
        .forEach((plugin:Plugin) => mapContext.plugins.push(plugin.toJson()));
      return mapContext;
    }
    throw new Error('currentContext is null');
  }

  setAccessList(access:any):any {
    if (this.currentApplication !== null) {
      this.currentApplication.access = access;
    }
    throw new Error('currentApplication is null');
  }

  saveContext():Promise<any> {
    const promise = new Promise((resolve, reject) => {
      if (this.currentContext !== null) {
        console.log('current CXT: ', this.currentContext);

        const mapContext = this.getJsonContext();

        if (this.currentApplication !== null) {
          console.log('JSON: ', this.currentApplication);
          this.currentApplication.config = mapContext;// JSON.stringify(mapContext);
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const self = this;
          const csrfToken = this.getCsrfToken();

          let request;

          if (this.currentApplication.id !== undefined) {
            request = axios.put(`${this.getMapsApiUrl()}/${this.currentApplication.id}/`,
              this.currentApplication,
              { headers: { 'X-CSRFToken': csrfToken } });
          } else {
            request = axios.post(`${this.getMapsApiUrl()}/`,
              this.currentApplication,
              { headers: { 'X-CSRFToken': csrfToken } });
          }
          this.loading = true;
          EventBus.$emit(EVENTS.INFO, 'Sauvegarde en cours....');
          // self.isEditionActive = false;
          request.then((res) => {
            // console.log("RESSS: ",res);
            self.loading = false;
            EventBus.$emit(EVENTS.INFO, 'Sauvegarde effectuée');
            // eslint-disable-next-line prefer-destructuring
            const id = res.data.id;
            self.getCurrentApplication().id = id;
            let thumb:any = self.currentApplication!.thumbnail;
            if (thumb != null && thumb instanceof File) {
              const file:any = thumb;
              const formData = new FormData();
              formData.append('file', file);
              const config = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'X-CSRFToken': csrfToken,
                },
              };
              axios.put(`${this.getMapsApiUrl()}/${id}/thumbnail/`, formData, config).then((response) => {
                console.log(response);
                resolve(self.getCurrentApplication());
              });
            } else {
              resolve(self.getCurrentApplication());
            }
          }, (res) => {
            console.log(res);
            self.loading = false;
            self.isEditionActive = true;
            if (res.response.data && res.response.data.error) {
              EventBus.$emit(EVENTS.ERROR, res.response.data.error);
            } else {
              EventBus.$emit(EVENTS.ERROR, res);
            }
          });
        }
      }
    });
    return promise;
  }

  loadConfig():Promise<AxiosResponse<any>> {
    const url = './sample_data/config.json';
    return axios.get(url);
  }

  loadSvgPictos():Promise<AxiosResponse<any>> {
    return axios.get('./styles/svg_icons.json');
  }

  addError(axiosReponse:Promise<AxiosResponse<any>>, message:string):Promise<AxiosResponse<any>> {
    return axiosReponse.catch((error) => {
      console.log(error);
      let m = message;
      if (error.response && error.response.data && error.response.data.message) {
        m = error.response.data.message;
      }
      EventBus.$emit(EVENTS.ERROR, m);
      return Promise.reject(error);
    });
  }

  signup(user: User):Promise<AxiosResponse<any>> {
    const request = this.addError(axios.post(`${this.getBaseApiUrl()}login/signup`, user), 'Erreur Inconnue');

    return request;
  }

  login(username:string, password:string):Promise<AxiosResponse<any>> {
    const url = `${this.getBaseApiUrl()}login/signin/`;


    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    const geoserverUrl = `/geoserver/j_spring_security_check`;
    //log into geoserver
    axios.post(geoserverUrl, params, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).then((result:any) => {
      if (result.status==200 ) {
        console.log('geoserver logged in')
      }
      else{
        console.error('geoserver auth failed');
        console.log(result);
      }
      
      return result;
    }).catch((error) => error.response);


    return axios.post(url, { username, password }).then((result:any) => {
      if (result.data.authenticated) {
        this.user = result.data.user;
        return result;
      }
      this.user = null;
      return result;
    }).catch((error) => error.response);
  }

  loadUserStatus(): void {
    const url = `${this.getBaseApiUrl()}login/session/`;
    axios.get(url).then((result:any) => {
      if (result.data.authenticated) {
        this.user = result.data.user;
        this.mapDefault = result.data.mapDefault
        this.mapNb = result.data.mapNb
        EventBus.$emit(EVENTS.CONFIG_LOADED, this.configuration);
      } else {
        this.user = null;
        EventBus.$emit(EVENTS.CONFIG_LOADED, this.configuration);
      }
    }).catch((error) => {
      console.log(error.response);
      EventBus.$emit(EVENTS.CONFIG_LOADED, this.configuration);
      if (error.response.status === 403 && error.response.data.code === 'terms-required') {
        this.termsValidationRequired = true;
      }
    });
  }

  async logout() :Promise<void>{
    const url = `${this.getBaseApiUrl()}login/signout/`;
    await axios.get(url)
    this.user = null;
  }

  isDatavizEnabled():boolean {
    return this.configuration.dataviz && this.configuration.dataviz.active;
  }

  isComplexStyleEnabled():boolean {
    return this.configuration.complexStyling && this.configuration.complexStyling.active;
  }

  getBaseApiUrl():string {
    return this.configuration.base_api_url;
  }

  getAdministrativeAreas():AdministrativeArea[] {
    return this.administrativeAreas;
  }

  getDownloadProjections():DownloadProjection[] {
    return this.downloadProjection;
  }

  getCorsProxyUrl():string {
    return this.configuration.cors_proxy_url;
  }

  getMapsApiUrl():string {
    return this.configuration.maps_api_url;
  }

  getExplorerUrl():string {
    if (this.configuration.explorer_url) {
      return this.configuration.explorer_url;
    }
    return '/portail';
  }

  getHomeUrl():string {
    if (this.configuration.home_url) {
      return this.configuration.home_url;
    }
    return '#';
  }

  getPublishUrl():string {
    if (this.configuration.publish_url) {
      return this.configuration.publish_url;
    }
    return '/onegeo-publish';
  }

  getOneGeoLoginUrl():string {
    if (this.configuration.login_url) {
      return this.configuration.login_url;
    }
    return '/onegeo-login';
  }

  getMapById(id:number):Promise<AxiosResponse<any>> {
    return axios.get(`${this.getMapsApiUrl()}/${id}/`);
  }

  getMaps(query = '', keywordFilter:string|undefined, pageNumber = 1,
    pageSize = 3, ordering = 'id', controller?:AbortController):Promise<AxiosResponse<any>> {
    let url = `${this.getMapsApiUrl()}/?format=json&q=${query}&highlight=true&page=${pageNumber}&page_size=${pageSize}&ordering=${ordering}`;
    if (keywordFilter) {
      url += `&tags=${keywordFilter}`;
    }
    const params:any = {};
    if (controller) {
      params.signal = controller.signal;
    }
    return axios.get(url, params);
  }

  getThumbnailUrl(map: Application): string {
    if (map.thumbnail != null) {
      return `${this.getMapsApiUrl()}/${map.id}/thumbnail/`;
    }
    throw new Error('thumbnail is null');
  }

  getProxyFiedUrl(url: string):string {
    // return `${this.getBaseApiUrl()}/proxy?url=${encodeURIComponent(url)}`;
    return `${this.getCorsProxyUrl()}${url}`;
  }

  loadOGCServerList():Promise<AxiosResponse<any>> {
    const url = './sample_data/serverList.json';
    return axios.get(url);
  }

  loadPaletteList():Promise<AxiosResponse<any>> {
    const url = './sample_data/color_palettes.json';
    return axios.get(url);
  }

  getApp(appId: number | string) :Promise<AxiosResponse<any>> {
    const url = `./sample_data/${appId}`;
    return axios.get(url);
  }

  loadUrl(url: string) :Promise<AxiosResponse<any>> {
    return axios.get(url);
  }

  isSuperUser() :boolean {
    return this.user && this.user.is_superuser;
  }

  isConnect(): boolean{
    return !!this.user;
  }

  removeAuthInfo(key: string) :void {
    delete this.localStorageAuthDB[key];
    localStorage.setItem('localStorageAuthDB', JSON.stringify(this.localStorageAuthDB));
  }

  addAuthInfo(key: string, authenticationInfos: AuthenticationInfos) :void {
    this.localStorageAuthDB[key] = authenticationInfos;
    localStorage.setItem('localStorageAuthDB', JSON.stringify(this.localStorageAuthDB));
  }

  getAuthInfos() :any {
    return this.localStorageAuthDB;
  }
  forceLogin() :boolean {
    return this.configuration.standalone_options && this.configuration.standalone_options.force_login && this.configuration.standalone_options.force_login==true   ;
  }
  enableMapDefault() :boolean {
    return this.configuration.standalone_options && this.configuration.standalone_options.enable_map_default && this.configuration.standalone_options.enable_map_default==true   ;
  }
  
  isGeoserverApiUrlDefined(){
    return this.configuration.geoserver_api_url && this.configuration.geoserver_api_url.length>0;
  }

  sendSld(layer: LayerConfig, styleName: string):Promise<AxiosResponse<any>> {
    console.log(layer.styletoSld());  
    console.log(styleName);  
    let geoserverAdminUrl=this.configuration.geoserver_api_url;

    const config = {
      headers: {
        'Content-Type': 'application/vnd.ogc.sld+xml',
        'Authorization': layer.authenticationInfos.getBasicAutorisationValue()
   
      },
    };
    let postUrl=`${geoserverAdminUrl}rest/workspaces/${layer.targetPrefix}/styles?name=${styleName}`;
    return axios.post(postUrl,layer.styletoSld(),config);
  }
  assignSld(layer: LayerConfig, styleName: string,defaultStyle:boolean):Promise<AxiosResponse<any>> {
    console.log(layer.styletoSld());  
    console.log(styleName);  
    let geoserverAdminUrl=this.configuration.geoserver_api_url;
   
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': layer.authenticationInfos.getBasicAutorisationValue()
      },
    };
    let data={
        style:{
          "name": styleName
        }
    };
    let postUrl=`${geoserverAdminUrl}rest/layers/${layer.layername}/styles?default=${defaultStyle}`;
    return axios.post(postUrl,data,config);
  }
}
const contexteServiceInstance = new ContextService();
export default ContextService;
export { ContextService, contexteServiceInstance };
