




















































































































































































































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Application from '@/models/map-context/Application';
import MapContext from '@/models/map-context/MapContext';
import { EventBus, EVENTS } from '@/services/EventBus';
import FileSaver from 'file-saver';
import Component from 'vue-class-component';
import BaseComponent from './BaseComponent';

@Component
export default class Header extends BaseComponent {
  logoUrl = null;// = 'img/logo_pigma.png';

  showLogin = false;

  showPublishLink = true;

  showExplorerLink = true;

  username = '';

  password = '';

  metabase_url: any = '';

  isDataViz = false;

  showPassword= false;

  errorLogin = false;

  canEdit=false;
  simpleHeader = false;

  created() {
    this.simpleHeader = this.contextService.simpleHeader;

    if (this.contextService.configuration.standalone) {
      this.showPublishLink = false;
      this.showExplorerLink = false;
    }

  }

  edit() {
    this.contextService.startEdition();
    EventBus.$emit(EVENTS.OPEN_LEFT_SIDE_MENU, 'SettingsPanel');
    console.log(this.contextService.user);
  }

  deleteMap() {
    if (window.confirm('Confirmez vous la suppression de cette carte?')) {
      this.contextService.deleteCurrentMap().then((x) => this.$router.push({ path: '/maps' }));
    }
  }

  newMap() {
    if (!this.checkOrganization()) {
      return;
    }
    this.$router.push({ path: '/map/new' });
  }

  help() {
    window.open(this.contextService.configuration.documentation_url, 'target=help');
  }

  redirect(url:string) {
    window.location.href = url;
  }

  redirect_blank(url: string) {
    window.open(url, '_blank');
  }

  async logout() {
    await this.contextService.logout();
    if(this.contextService.forceLogin()){
    this.$router.push({ path: '/login/' });
    }
    else{
      this.$router.push({ path: '/maps/' });
    }
 
  }

  openContext() {
    const input:any = document.createElement('input');
    input.type = 'file';
    input.onchange = () => {
      // you can use this method to get file and perform respective operations
      const files = Array.from(input.files);
      console.log(files);
      if (files.length === 1) {
        const file:any = files[0];
        const reader = new FileReader();
        reader.addEventListener('load', (event:any) => {
          console.log(event.target.result);
          const appConfig = JSON.parse(event.target.result);
          console.log('loading ', appConfig);
          appConfig.id = undefined;
          appConfig.usergroup = undefined;
          appConfig.thumbnail = undefined;
          appConfig.creation_date = undefined;
          appConfig.last_revision_date = undefined;
          // store context before reload to route that will load it
          this.contextService.localApplication = new Application(appConfig);
          this.contextService.localContext = new MapContext(appConfig.config);
          const times:any = Date.now();
          this.$router.push({ path: '/map/new', query: { local: 'true', ts: times }, append: true });
        });
        reader.readAsText(file);
      }
    };
    input.click();
  }

  downloadContext() {
    const context = this.contextService.getJsonContext();
    const app:any = this.contextService.currentApplication;
    app.config = context;
    console.log(JSON.stringify(app));
    const blob = new Blob([JSON.stringify(app)],
      { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(blob, 'context.json');
  }

  submitSignup() {
    this.showLogin = false;
    this.$router.push({ path: '/users/signup' });
  }

  submitlogin() {
    this.contextService.login(this.username, this.password).then((result) => {
      if (result.data.authenticated) {
        this.$bvToast.toast(`${result.data.user.name} connecté`, {
          title: 'Connexion réussie',
          variant: 'success',
          autoHideDelay: 0e3,
          solid: true,
        });
        this.showLogin = false;
        this.errorLogin = false;
      } else {
        this.errorLogin = true;
      }
    });
  }

  login() {
    // this.contextService.login(); DEV to remove
    if (this.contextService.configuration.standalone) {
      this.showLogin = true;
    } else {
      const redirectUrl = window.location.pathname + window.location.search;
      if (this.contextService.termsValidationRequired) {
        this.redirect(`${this.contextService.getOneGeoLoginUrl()}/terms-of-use?next=${redirectUrl}`);
      } else {
        this.redirect(`${this.contextService.getOneGeoLoginUrl()}/signin?next=${redirectUrl}`);
      }
    }
  }

  profile() {
    if (this.contextService.configuration.standalone) {
      this.$router.push({ path: `/users/${this.contextService.user.id}`, query: { parentProps: 'profil' } });
    } else {
      const redirectUrl = window.location.pathname + window.location.search;
      this.redirect(`${this.contextService.getOneGeoLoginUrl()}/profile?next=${redirectUrl}`);
    }
  }

  cancelEdition() {
    this.contextService.cancelEdition();
    if (this.contextService.currentApplication && !this.contextService.currentApplication.id) {
      this.redirect('#/maps/');
    }
  }


  get mapTitle() {
    if (this.contextService.currentApplication !== null) {
      document.title = this.contextService.currentApplication.display_name;
      return this.contextService.currentApplication.display_name;
    }
    return '';
  }

  get canShowUserList() {
    return this.contextService.configuration.standalone && this.contextService.user.is_superuser;
  }

  get canEditMap() {
    if (this.contextService.currentApplication === null
    || this.contextService.user === null
    || this.contextService.currentApplication === undefined) { return false; }

    if (this.contextService.configuration.standalone) {
      const currentAppUserId = this.contextService.currentApplication.ownerId;
      this.canEdit = this.contextService.hasUserEditAccess();
      if (this.contextService.currentApplication.id === undefined
      || this.contextService.user.is_superuser
      || this.contextService.user.id === currentAppUserId
      || this.canEdit) {
        return true;
      }
    } else {
      const currentAppUserGroupId = this.contextService.currentApplication.usergroup;
      if (this.contextService.currentApplication.id === undefined
      || this.contextService.user.is_superuser
      || this.contextService.user.usergroup_roles
        .filter((x:any) => x.usergroup.id === currentAppUserGroupId).length > 0) {
        return true;
      }
    }

    return false;
  }

  get canDeleteMap() {
    if (this.contextService.currentApplication
      && this.contextService.currentApplication.id
      && this.canEditMap) {
      return true;
    }

    return false;
  }

  checkOrganization() {
    const hasOrganization = this.contextService?.user?.usergroup_roles?.length > 0;
    if (!hasOrganization) {
      EventBus.$emit(EVENTS.ERROR, 'Votre compte doit être attaché à une organisation pour créer une carte');
      return false;
    }
    return true;
  }

  saveContext() {
    if (!this.checkOrganization()) {
      return;
    }
    const reload = this.contextService.currentApplication
      && this.contextService.currentApplication.id === undefined;
    if (reload && this.contextService.currentApplication
      && this.contextService.currentApplication.display_name === 'Mon appli') {
      EventBus.$emit(EVENTS.ERROR, 'Il est nécessaire de modifier le titre par defaut');
      EventBus.$emit(EVENTS.OPEN_LEFT_SIDE_MENU, 'SettingsPanel');
      return;
    }
    this.contextService.saveContext().then((app:Application) => {
      if (reload) {
        this.$router.push({ path: `/map/${app.id}` });
      }
    });
  }

  saveAndCloseContext() {
    const reload = this.contextService.currentApplication
      && this.contextService.currentApplication.id === undefined;
    if (reload && this.contextService.currentApplication
      && this.contextService.currentApplication.display_name === 'Mon appli') {
      EventBus.$emit(EVENTS.ERROR, 'Il est nécessaire de modifier le titre par defaut');
      EventBus.$emit(EVENTS.OPEN_LEFT_SIDE_MENU, 'SettingsPanel');
      return;
    }
    this.contextService.saveContext().then((app:Application) => {
      EventBus.$emit(EVENTS.OPEN_LEFT_SIDE_MENU, '');
      this.contextService.cancelEdition();
      if (reload) {
        this.$router.push({ path: `/map/${app.id}` });
      }
    });
  }

  mounted() {
    this.logoUrl = this.contextService.configuration.header.logo;

    this.metabase_url = this.pluginService.getParameter('DataVizPlugin', 'url_metabase');

    // this.mapService = mapservice;
  }
}
