












































































































































/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import FormHtmlEditor from '@/components/shared/FormHtmlEditor.vue';
import FormNumberInput from '@/components/shared/FormNumberInput.vue';
import FormTextInput from '@/components/shared/FormTextInput.vue';
import LayerAttribute from '@/models/map-context/LayerAttribute';
import LayerConfig from '@/models/map-context/LayerConfig';
import Component from 'vue-class-component';
import BaseComponent from '../../BaseComponent';

@Component({
  props: {
    layer: LayerConfig,
  },
  components: {
    FormTextInput,
    FormNumberInput,
    FormHtmlEditor
  },
})
export default class SingleLayerAttributeInfos extends BaseComponent {
  layer!: LayerConfig;

  errorTxt: string | null = null;

  selectAll = false;

  guessMode = false;

  newKey: string = "";

  newValue: string = "";

  listItems: any = [];

  showListModal = false;

  selectedField: any | null = null;

  public attributeTypes = [{ value: 'xsd:int', label: 'Entier' },
    { value: 'xsd:string', label: 'Texte' },
    { value: 'xsd:number', label: 'Nombre' },
    { value: 'xsd:boolean', label: 'Booléen' },
    { value: 'xsd:double', label: 'Double' },
  ];

  public widgetTypes = [
    { value: 'TextForm', label: 'Input texte' },
    { value: 'ListForm', label: 'Input Liste' },
    { value: 'NumberForm', label: 'Input nombre' },
    { value: 'Checkbox', label: 'Case à cocher' },
    // { value: 'fileUpload', label: 'FileUpload' },
  ];

  editorLoaded = false;

  properties:any= [];


  mounted() {
    if (this.layer.layerProperties.length === 0 && this.layer.isVector()) {
      // No properties, try to guess from datas
      this.guessMode = true;
      const features = this.layer.getLayerSource().getFeatures();
      if (features && features.length > 0) {
        if (this.is3DView()) {
          features[0].properties.propertyNames.forEach((element: any) => {
            this.layer.layerProperties.push(new LayerAttribute({
              name: element,
              type: 'xsd:string',
              widgetType: 'TextForm',
              editable: true,
              display: true,
            }));
          });
        } else {
          features[0].getKeys().forEach((element: any) => {
            this.layer.layerProperties.push(new LayerAttribute({
              name: element,
              type: 'xsd:string',
              widgetType: 'TextForm',
              editable: true,
              display: true,
            }));
          });
        }
      }
    }
    this.properties=this.layer.layerProperties;
    if (this.layer.ficheInfos.active) {
      this.editorLoaded = true;
    }
  } 

  onChangeFicheInfoType() {
    if (this.layer.ficheInfos.active && (this.layer.ficheInfos.content === '' || this.layer.ficheInfos.content === undefined)) {
      this.initFicheContent();
    }
    this.editorLoaded = true;
  }

  openListModal(field: any) {
    this.selectedField = field;
    this.listItems = this.selectedField.widgetConfig ? [...this.selectedField.widgetConfig] : [];
    this.showListModal = true;
  }

  addKeyValue(field: any) {
    if (!field.widgetConfig || field.widgetConfig.length <=0) {
      field.widgetConfig = [];
      // field.widgetConfig = [{key: field.type, value: field.value}];
    }
    field.widgetConfig.push({ key: this.newKey, value: this.newValue });
    this.newKey = '';
    this.newValue = '';
    this.listItems = field.widgetConfig;
  }

  onChangeType(field: any) {
    this.getWidgetType(field)
  } 

  getWidgetType(field: any){
    if(field.isText()){
      field.widgetType = 'TextForm'
    } else if(field.isBoolean()){
      field.widgetType = 'Checkbox'
    } else if(field.isNumber()){
      field.widgetType = 'NumberForm'
    }
  }

  removeListItem(index: any) {
    if (this.selectedField) {
      this.listItems.splice(index, 1);
      // Mettre à jour widgetConfig
      this.selectedField.widgetConfig = this.listItems;
    }
  }


  initFicheContent() {
    this.layer.ficheInfos.content = '';
    if (this.layer.layerProperties) {
      for (let i = 0; i < this.layer.layerProperties.length; i += 1) {
        this.layer.ficheInfos.content += `<p>${this.layer.layerProperties[i].name}:{${this.layer.layerProperties[i].name}}</p>`;
      }
    }
  }

  isAttributeEditable(field: any) {
    return (this.layer.editable || this.guessMode) && this.contextService.isEditionActive;
  }

  widgetTypesForFieldType(field: any) {
    if (field.isText()) {
      return this.widgetTypes.filter(widget => ['Input texte', 'Input Liste'].includes(widget.label));
    } else {
      return this.widgetTypes;
    }
  }

  getFieldType(value: string) {
    if (value.indexOf('xsd:') >= 0) {
      return value.split(':')[1];
    }
    return value;
  }

  reloadAttributes() {
    // eslint-disable-next-line no-alert, no-restricted-globals
    if (confirm('Etes-vous sur de vouloir rechargez la définition des attributs depuis le serveur?')) {
      this.getMapService().setAttributeDefinition(this.layer, true);
      setTimeout(()=>{
        this.properties=this.layer.layerProperties;
        },3000);
    }
  }

  addAttribute() {
    this.layer.layerProperties.push(new LayerAttribute({
      name: `nouveau_${this.layer.layerProperties.length}`,
      type: 'xsd:string',
      widgetType: 'TextForm',
      editable: true,
      display: true,
    }));
    this.properties=this.layer.layerProperties;
  }
}
